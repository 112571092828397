import { Pipe, PipeTransform } from "@angular/core";
import * as moment from "moment";

@Pipe({
    name: 'toUtcTime'
})
export class ToUtcTimePipe implements PipeTransform {
    transform(value: string): string {
        let diffHours = moment().utcOffset() / 60;
        let [hours, minutes] = value.split(":").map(Number);
        hours -= diffHours;
        const utcTime = `${String(Math.floor(hours)).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
        return utcTime;
    }
}