<div class="main_container" [style.background-image]="'url(&quot;https://walinwa.blob.core.windows.net/images/esquina-inf-der.svg&quot;)'">
    <section fxLayout="column" fxLayoutAlign="stretch" class="box_options">
        <div class="box_op">
            <div class="title">
                <span *ngIf="step == 1">{{ 'AUTHENTICATION.FREE-TRIAL.FREE-TRIAL-EDUCATIONAL.ADMIN-INFO.TITLE' | translate }}</span>
                <span *ngIf="step == 2">{{ 'AUTHENTICATION.FREE-TRIAL.VERIFY-MAIL-CODE' | translate }}</span>
            </div>
            <div class="cont">
                <form class="walinwa-box-content" [formGroup]="adminInfoForm" autocomplete="new-password"
                    *ngIf="step == 1">
                    <div fxLayout="row" fxLayout.lt-md="column" fxNgClass.gt-sm="spe_gap">
                        <div fxFlex="">
                            <label class="font_blue">{{ 'EMAIL' | translate }}</label>
                            <mat-form-field class="spe-form-field">
                                <input matInput type="text" formControlName="email">
                                <mat-error *ngIf="adminInfoForm.get('email').invalid">
                                    {{ 'EMAIL-INVALID' | translate }}
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div fxFlex="">
                            <label class="font_blue">{{ 'PASSWORD' | translate }}</label>
                            <mat-form-field class="spe-form-field">
                                <input matInput [type]="hide ? 'password' : 'text'" formControlName="passwd">
                                <mat-icon [svgIcon]="hide ? 'gafas_on' : 'gafas_off'" matSuffix (click)="hide = !hide">
                                </mat-icon>

                                <mat-error *ngIf="adminInfoForm.get('passwd').hasError('required')">
                                    {{ 'FIELD-REQUIRED' | translate }}
                                </mat-error>
                                <mat-error *ngIf="adminInfoForm.get('passwd').errors?.minlength">
                                    {{ 'PASSWORD-LENGTH' | translate }}
                                </mat-error>
                                <mat-error *ngIf="adminInfoForm.get('passwd').errors?.maxlength">
                                    {{ 'PASSWORD-LENGTH' | translate }}
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div fxLayout="row" fxLayout.lt-md="column" fxNgClass.gt-sm="spe_gap">
                        <div fxFlex="">
                            <label class="font_blue">{{ 'NAME' | translate }}</label>
                            <mat-form-field class="spe-form-field">
                                <input matInput type="text" formControlName="name">
                                <mat-error *ngIf="adminInfoForm.get('name').hasError('pattern')"
                                    class="customized_error two">
                                    {{ 'PATTERN-ERROR' | translate }}
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div fxFlex="">
                            <label class="font_blue">{{ 'FAMILYNAME' | translate }}</label>
                            <mat-form-field class="spe-form-field">
                                <input matInput type="text" formControlName="surname">
                                <mat-error *ngIf="adminInfoForm.get('surname').hasError('pattern')"
                                    class="customized_error two">
                                    {{ 'PATTERN-ERROR' | translate }}
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div fxLayout="row" fxLayout.lt-md="column" fxNgClass.gt-sm="spe_gap">
                        <div fxFlex="">
                            <label class="font_blue">{{ 'AUTHENTICATION.FREE-TRIAL.FREE-TRIAL-EDUCATIONAL.ADMIN-INFO.TELEPHONE' | translate }}</label>
                            <mat-form-field class="spe-form-field">
                                <input matInput type="tel" class="form-control" formControlName="phone">
                                <mat-error *ngIf="adminInfoForm.get('phone').hasError('required')">
                                    {{ 'FIELD-REQUIRED' | translate }}
                                </mat-error>
                                <mat-error *ngIf="adminInfoForm.get('phone').errors?.minlength">
                                    {{ 'AUTHENTICATION.FREE-TRIAL.FREE-TRIAL-EDUCATIONAL.ADMIN-INFO.TELEPHONE-LENGTH' | translate }}
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div fxFlex="">
                            <label class="font_blue">{{ 'AUTHENTICATION.FREE-TRIAL.FREE-TRIAL-EDUCATIONAL.ADMIN-INFO.WALINWA-USER' | translate }}<sup>1</sup></label>
                            <mat-form-field class="spe-form-field disable">
                                <input matInput type="text" formControlName="walinwauser">
                            </mat-form-field>
                        </div>
                    </div>
                    <div *ngIf="errorMessage" class="error-message">
                        <mat-error>
                            {{ errorMessage }}
                        </mat-error>
                    </div>
                    <div>
                        {{ 'AUTHENTICATION.FREE-TRIAL.FREE-TRIAL-EDUCATIONAL.ADMIN-INFO.USER-AUTO-INFO' | translate }}
                    </div>
                    <div style="margin-top: 1rem;">
                        <mat-checkbox formControlName="privacy">
                            <div [innerHTML]=" 'AUTHENTICATION.FREE-TRIAL.SERVICE-AND-POLICY' | translate: {url: url}">
                            </div>
                            <mat-error *ngIf="!this.adminInfoForm.value.privacy && submited">
                                {{'ACCEPT-CONDITIONS-ERROR' | translate}}
                            </mat-error>
                        </mat-checkbox>
                        
                    </div>
                    <div fxLayoutAlign="end end" fxLayoutAlign.xs="center center" style="margin-top: 35px !important;">
                        <button mat-raised-button (click)="finish($event,1)" class="submit-button orange fixed_button">
                            {{ 'CONTINUE' | translate }}
                        </button>
                    </div>
                </form>
                <div fxLayout="column" fxLayoutAlign="stretch" *ngIf="step == 2">
                    <div fxLayout="row" fxLayout.lt-md="column" fxNgClass.gt-sm="spe_gap">
                        <div>
                            <label class="font_blue">{{'AUTHENTICATION.FREE-TRIAL.CODE-INFO' | translate}}</label>
                            <mat-form-field class="spe-form-field">
                                <input matInput type="text" class="form-control " name="userCode"
                                    [(ngModel)]="userCode">
                            </mat-form-field>
                        </div>
                        <div fxHide fxShow.gt-sm fxFlex="">
                            <!--  -->
                        </div>
                    </div>
                    <div *ngIf="codeError" class="error-message">
                        <mat-error>
                            {{ 'AUTHENTICATION.ERROR.CODE' | translate }}
                        </mat-error>
                        
                    </div>
                    <div fxLayout="row wrap" fxLayoutAlign="space-between center" fxLayoutAlign.xs="center center" class="spe_gap" style="margin-top: 35px !important;">
                        <button [disabled]="timer > 0" mat-raised-button class="submit-button fixed_button" (click)="finish($event,1)" color="accent">
                            {{ timer > 0 ? timer : 'AUTHENTICATION.FREE-TRIAL.RESEND' | translate }}
                        </button>
                        <button mat-raised-button (click)="finish($event,2)" class="submit-button orange fixed_button">
                            {{ 'ACCEPT' | translate }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>