import { ClassConfigDTO } from './../core/shared/state/models/Class/class-config-model';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { Subject } from 'rxjs';
import { ActivitiesConfigDTO } from 'app/core/shared/state/models/Class/activities-config.model';
import { GameConfig } from 'app/core/shared/state/game-config.model';
import { ImportData } from 'app/main/school/admin/admin-classroom/admin-classroom-data/admin-classroom-data.component';

@Injectable()
export class SchoolService {

    baseUrl = environment.baseApi + '/api/School';
    school;
    extrafields = false;
    private s_is_teacher = new Subject<boolean>();
    is_teacher = this.s_is_teacher.asObservable();
    directory = "https://walinwa.blob.core.windows.net/images/School/"
    private s_test_session = new Subject<boolean>();
    test_session = this.s_test_session.asObservable();

    ordenColumnas = {
        1: [
            "Nombre",
            "Nombre y apellido/s",
            "Apellido/s y nombre",
        ],
        2: ["1. Nombre. 2. Apellidos", "1. Apellidos. 2. Nombre"],
        3: [
            "1. Nombre. 2. 1er Apellido. 3. 2º Apellido",
            "1. 1er Apellido. 2. 2º Apellido. 3. Nombre",
        ],
    };

    numColumnas = [
        { key: 1, name: "Una columna" },
        { key: 2, name: "Dos columnas" },
        { key: 3, name: "Tres columnas" },
    ];
    importDataConfig = {
        descartarPrimeraFila: false,
        numeroColumnasImportar: 2,
        ordenColumnas: 0,
        nombreApellidosSeparados: false,
    };
    constructor(private http: HttpClient) {
    }
    changeIsTeacher(is_teacher: boolean) {
        this.s_is_teacher.next(is_teacher);
    }
    changeTestSession(test_session: boolean){
        this.s_test_session.next(test_session);
    }
    isUserAssigned(IdUser: number) {
        return this.http.get(this.baseUrl + "/isUserAssigned/IdUser/" + IdUser);
    }
    setInitials(schoolName) {
        return this.http.get(this.baseUrl + "/setinitials/" + schoolName);
    }
    addSchool(school, admin, password) {
        let params = { school: school, admin: admin, password: password };
        return this.http.post(this.baseUrl + "/addSchool", params);
    }

    cloneSchool(school, admin, idSchoolOrigin) {
        let params = { school: school, admin: admin, idSchoolOrigin: idSchoolOrigin };
        return this.http.post(this.baseUrl + "/cloneSchool", params);
    }

    addTeacher(teacher, idSchool, password) {
        let params = { teacher: teacher, idSchool: idSchool, password: password };
        return this.http.post(this.baseUrl + "/addTeacher", params);
    }

    addStudent(student, classCode, password, defaultPw, location) {
        let params = { student: student, classCode: classCode, password: password, defaultPw: defaultPw, location: location };
        return this.http.post(this.baseUrl + "/addStudent", params);
    }

    updateStudent(idUser, name, familyName, alias, level, idClass, userName, multTime, smallSession, blockName, blockAlias, blockContact, giftShopDisabled, blockMessageGift, avatarDisabled) {
        let params = { name: name, familyName: familyName, alias: alias, level: level, idClass: idClass, userName: userName, multTime: multTime, smallSession: smallSession, blockName: blockName, blockAlias: blockAlias, blockContact: blockContact, giftShopDisabled: giftShopDisabled, blockMessageGift: blockMessageGift, avatarDisabled: avatarDisabled };
        return this.http.post(this.baseUrl + "/updateStudent/" + idUser, params);
    }

    updateSchool(school) {
        return this.http.post(this.baseUrl + "/updateSchool/" + school.IdSchool, school);
    }
    updateSchoolBySchoolAdmin(school: any){
        return this.http.post(this.baseUrl + "/updateSchoolBySchoolAdmin/" + school.IdSchool, school);
    }
    updateTeacher(idUser, name, familyName, email, userName, password) {
        let params = { name: name, familyName: familyName, email: email, userName: userName, password: password };
        return this.http.post(this.baseUrl + "/updateTeacher/" + idUser, params);
    }

    addClass(name, level, idTeacher) {
        let params = { name: name, level: level, idTeacher: idTeacher };
        return this.http.post(this.baseUrl + "/addClass", params);
    }
    generateClasses(names, courses, idTeacher) {
        let params: FormData = new FormData();
        for (var i = 0; i < courses.length; i++) {
            params.append('levels[]', courses[i]);
        }
        for (var i = 0; i < names.length; i++) {
            params.append('names[]', names[i]);
        }
        params.append("idTeacher", idTeacher);
        return this.http.post(this.baseUrl + "/addClasses/", params);
    }
    updateSimpleClass(id: number, name: string, level: number, idTeacher: number, code: string)
    {
        let params = { name: name, level: level, idTeacher: idTeacher, code: code }
        return this.http.post(this.baseUrl + "/UpdateSimpleClass/" + id, params);
    }

    updateClassOptions(IdClass, IdUser, BlockName, BlockAlias, BlockContact, GiftShopDisabled, BlockMessageGift, AvatarDisabled) {
        return this.http.post(this.baseUrl + "/UpdateClassOptions", { IdClass, IdUser, BlockName, BlockAlias, BlockContact, GiftShopDisabled, BlockMessageGift, AvatarDisabled });
    }

    deleteClass(idClass, idAdmin, isSchoolAdmin) {
        return this.http.post(this.baseUrl + '/deleteClass/idClass/' + idClass + "/idAdmin/" + idAdmin + "/isSchoolAdmin/" + isSchoolAdmin, null);
    }

    deleteClassStudents(idClass, idAdmin, isSchoolAdmin) {
        return this.http.post(this.baseUrl + '/deleteClassStudents/idClass/' + idClass + "/idAdmin/" + idAdmin + "/isSchoolAdmin/" + isSchoolAdmin, null);
    }

    validateInitials(initials) {
        return this.http.get(this.baseUrl + '/validate/' + initials);
    }

    validateNameAndDomain(name, email) {
        return this.http.get(this.baseUrl + '/validateNameAndDomain/' + name + '/' + email);
    }

    getStudentName(initials, name, classCode, familyName?) {
        return this.http.post(this.baseUrl + '/getStudentName/' + initials + "/" + name + "/" + classCode, { familyName: familyName });
    }

    getTeacherName(initials, name, familyName) {
        return this.http.get(this.baseUrl + '/getTeacherName/' + initials + "/" + name + ((familyName || '').length === 0 ? "/undefined" : "/" + familyName));
    }

    getStudentsName(idSchool, initials, alumns, classCode, ImportStudents = false) {
        let params = { idSchool: idSchool, initials: initials, alumns: alumns, classCode: classCode, ImportStudents };
        return this.http.post(this.baseUrl + "/getStudentsName", params);
    }

    getTeachersName(idSchool, initials, teachers) {
        let params = { idSchool: idSchool, initials: initials, teachers: teachers };
        return this.http.post(this.baseUrl + "/getTeachersName", params);
    }

    getSimpleClass(IdClass: number) {
        return this.http.get(this.baseUrl + '/GetClass/' + IdClass);
    }
    getClass(idClass) {
        return this.http.get(this.baseUrl + '/idClass/' + idClass);
    }
    getDictConfig(idClass: number) {
        return this.http.get(this.baseUrl + '/getDictConfig/' + idClass);
    }

    getClassToEdit(IdClass: number) {
        return this.http.get(this.baseUrl + '/getClassToEdit/' + IdClass);
    }
    getStudentsSchool(idSchool) {
        return this.http.get(this.baseUrl + '/students/' + idSchool);
    }

    getCountStudentsForClientByIdSchool(idSchool) {
        return this.http.get(this.baseUrl + '/students/count/' + idSchool);
    }

    getStudentsTutor(idTutor) {
        return this.http.get(this.baseUrl + '/studentsByTutor/' + idTutor);
    }

    getSchoolByUser(idUser) {
        return this.http.get(this.baseUrl + '/getSchool/' + idUser);
    }

    getSchoolParcialByUser(idUser) {
        return this.http.get(this.baseUrl + '/getSchoolParcial/' + idUser);
    }

    getSchoolInitialsById(idSchool) {
        return this.http.get(this.baseUrl + '/getSchoolInitials/' + idSchool);
    }

    resetPassword(idUser, idAdmin, isAdmin) {
        return this.http.post(this.baseUrl + '/resetPassword/idUser/' + idUser + "/idAdmin/" + idAdmin + "/isAdmin/" + isAdmin, {});
    }
    DeleteStudent(IdUser: number) {
        return this.http.get(this.baseUrl + '/deleteStudent/' + IdUser);
    }
    deleteUser(idUser, idAdmin, isSchoolAdmin) {
        return this.http.post(this.baseUrl + '/deleteUser/idUser/' + idUser + "/idAdmin/" + idAdmin + "/isSchoolAdmin/" + isSchoolAdmin, {});
    }

    preDeleteUser(idUser, idAdmin, isSchoolAdmin) {
        return this.http.post(this.baseUrl + '/predeleteUser/idUser/' + idUser + "/idAdmin/" + idAdmin + "/isSchoolAdmin/" + isSchoolAdmin, {});
    }

    deleteSchool(idSchool, isAdmin) {
        return this.http.post(this.baseUrl + '/deleteSchool/idSchool/' + idSchool + '/isAdmin/' + isAdmin, {});
    }
    deleteClasses(idSchool, isAdmin) {
        return this.http.post(this.baseUrl + '/deleteClasses/idSchool/' + idSchool + '/isAdmin/' + isAdmin, {});
    }
    deleteStudents(idSchool, isAdmin) {
        return this.http.post(this.baseUrl + '/deleteStudents/idSchool/' + idSchool + '/isAdmin/' + isAdmin, {});
    }
    searchSchool(searchText, num, isAdmin) {
        let params = { searchText: searchText, number: num, isAdmin: isAdmin };
        return this.http.post(this.baseUrl + "/searchSchool", params);
    }

    getSchoolLicenseByIdSchool(idSchool) {
        return this.http.get(this.baseUrl + '/getSchoolLicense/' + idSchool);
    }

    startDemo(idSchool, curso, tutor) {
        return this.http.get(this.baseUrl + '/startDemo/' + idSchool + '/' + curso + '/' + tutor);
    }
    getSchoolById(idSchool: number) {
        return this.http.get(this.baseUrl + '/getSchool/idSchool/' + idSchool);
    }
    GetSchoolAndFacturasById(idSchool: number) {
        return this.http.get(this.baseUrl + '/getSchoolAndFacturas/idSchool/' + idSchool);
    }

    getSchools() {
        return this.http.get(this.baseUrl + '/getSchools/');
    }
    getSchoolsAndFacturas() {
        return this.http.get(this.baseUrl + '/getSchoolsAndFacturas/');
    }
    //getSchool/{idSchool}/classes
    getSchoolClasses(idSchool) {
        return this.http.get(this.baseUrl + '/getSchool/' + idSchool + '/classes');
    }

    //getSchool/{idSchool}/teachers
    getSchoolTeachers(idSchool) {
        return this.http.get(this.baseUrl + '/getSchool/' + idSchool + '/teachers');
    }

    getDictBlock(idClass) {
        if (idClass === null)
            return this.http.get(this.baseUrl + '/getDictBlock/' + 0);
        return this.http.get(this.baseUrl + '/getDictBlock/' + idClass);
    }

    getGameBlock(idClass) {
        return this.http.get(this.baseUrl + '/getGameBlock/' + idClass);
    }

    saveDictConfig(Idtutor: number, IdClass: number, DictIni: string, DictEnd: string, DictSpace: boolean,AccentPenalty: boolean) {
        if(DictIni == "") DictIni = null;
        if(DictEnd == "") DictEnd = null;
        const data: ClassConfigDTO = {
            IdTutor: Idtutor,
            IdClass: IdClass,
            DictIni: DictIni,
            DictEnd: DictEnd,
            DictSpace: DictSpace,
            AccentPenalty: AccentPenalty
        }
        return this.http.post(this.baseUrl + '/saveDictConfig', data);
    }

    saveActivitiesConfig(Idtutor: number, IdClass: number, HearIni: string, HearEnd: string, DedIni: string, DedEnd: string, ReadIni: string, ReadEnd: string) {
        if(HearIni == "") HearIni = null;
        if(HearEnd == "") HearEnd = null;
        if(DedIni == "") DedIni = null;
        if(DedEnd == "") DedEnd = null;
        if(ReadIni == "") ReadIni = null;
        if(ReadEnd == "") ReadEnd = null;
        const data: ActivitiesConfigDTO = {
            IdTutor: Idtutor,
            IdClass: IdClass,
            HearIni: HearIni,
            HearEnd: HearEnd,
            DedIni: DedIni,
            DedEnd: DedEnd,
            ReadIni: ReadIni,
            ReadEnd: ReadEnd,
        }
        return this.http.post(this.baseUrl + '/saveActivitiesConfig', data);
    }

    saveChallengeConfig(IdUser, IdClass, RetoIni, RetoEnd) {
        if (RetoIni == "") RetoIni = null;
        if (RetoEnd == "") RetoEnd = null;
        return this.http.post(this.baseUrl + '/saveChallengeConfig', { IdUser, IdClass, RetoIni, RetoEnd });
    }

    saveGameConfig(gameConfig: GameConfig) {
        if (gameConfig.GameIni == "") gameConfig.GameIni = null;
        if (gameConfig.GameEnd == "") gameConfig.GameEnd = null;
        
        return this.http.post(this.baseUrl + '/saveGameConfig', gameConfig);
    }

    saveSessionConfig(IdUser, IdClass, MultTime, SmallSession, SmallSessionWeekend, SmallSessionSummer, Retrieve: boolean, WeekendSkipTodaySession: boolean, AllSameWalinwos: boolean) {
        return this.http.post(this.baseUrl + '/saveSessionConfig', { IdUser, IdClass, MultTime, SmallSession, SmallSessionWeekend, SmallSessionSummer, Retrieve, WeekendSkipTodaySession, AllSameWalinwos });
    }

    addAuthTeacher(IdClass, IdUserTuTor) {
        return this.http.post(this.baseUrl + '/AddAuthTeacher', { IdClass, IdUserTuTor });
    }

    removeAuthTeacher(IdClass, IdUserTuTor) {
        return this.http.post(this.baseUrl + '/RemoveAuthTeacher', { IdClass, IdUserTuTor });
    }

    updateSchoolInitials(idschool, initials) {
        return this.http.get(this.baseUrl + '/updateSchoolInitials/' + idschool + '/' + initials);
    }

    moveSchool(idSchool,deleteClassesAndStudents : boolean, deleteStudents: boolean) {
        if (deleteClassesAndStudents == null) {
            deleteClassesAndStudents = false;
        }
        if (deleteStudents == null) {
            deleteStudents = false;
        }
        let params = {
            deleteClassesAndStudents: deleteClassesAndStudents,
            deleteStudents: deleteStudents
        }
        return this.http.post(this.baseUrl + "/moveSchool/" + idSchool, params);
    }

    getAllExamDates(classesId) {
        return this.http.post(this.baseUrl + '/getExamDates', classesId);
    }
    getUserAsignedClasses(idUser, classesId) {
        return this.http.post(this.baseUrl + '/userClasses/' + idUser, classesId);
    }
    getAllControlSessionDates(classesId) {
        return this.http.post(this.baseUrl + '/getControlSessionDates', classesId);
    }
    getAllPersonalizedTestDates(classesId) {
        return this.http.post(this.baseUrl + '/getPersonalizedTestDates', classesId);
    }

    addDiscountCode(data) {
        return this.http.post(this.baseUrl + "/addDiscountCode/", data);
    }
    getWaliFacturaLineas(Num: string){
        return this.http.post(this.baseUrl + "/getWaliFacturaLineas", {Num});
    }
    
    importClassroomData(classroomImportData: ImportData) {
        return this.http.post(this.baseUrl + '/ImportClassroomData', classroomImportData);
    }
}
