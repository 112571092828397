import { UserService } from 'app/services/user.service';
import { Component, OnInit, ViewEncapsulation, HostListener, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { environment } from '../../../../../environments/environment'
import { UiService } from '@fuse/components/spinner/ui.service';
import { SharedService } from 'app/core/shared/shared.service';
import { User, createUser } from 'app/core/shared/state/models/user.model';
import { DataService } from 'app/core/shared/state';
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'free-trial-particular',
    templateUrl: './free-trial-particular.component.html',
    styleUrls: ['./free-trial-particular.component.scss'],
    encapsulation: ViewEncapsulation.None,
    host: {
        '(window:resize)': 'onResize($event)'
    }
})
export class FreeTrialParticularComponent implements OnInit, OnDestroy {
    private _unsubscribeAll: Subject<any>;

    errorMessage: string;
    freeTrialParticularForm: UntypedFormGroup;
    name: string;
    surname: string;
    mail: string;
    url: string;
    continue: boolean = false;
    isChecked = false;
    user: User;
    submited = false;
    step = 1;
    code = Math.floor(100000 + Math.random() * 900000);
    codeError = false;
    userCode = null;
    timer = 10;
    regExp = /^[a-zA-Z0-9ñÑáéíóúÁÉÍÓÚüÜäÄëËïÏöÖç\s\.\-'ª’]+$/u
    constructor(
        private _formBuilder: UntypedFormBuilder,
        private _router: Router,
        public _sharedService: SharedService,
        private _dataService: DataService,
        private _ui: UiService,
        private userService: UserService,
    ) {
        this._unsubscribeAll = new Subject();
        this.url = environment.url;
        this._ui.spin$.next(true);
        
    }
    
    ngOnDestroy() {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        event.target.innerWidth;
    }

    ngOnInit(): void {
        this.freeTrialParticularForm = this._formBuilder.group({
            name: ['', [Validators.required, Validators.pattern(this.regExp)]],
            familyName: ['', [Validators.pattern(this.regExp)]],
            email: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9._%+-]+[a-zA-Z0-9]+@+[a-zA-Z0-9]+.[a-zA-Z]{2,4}$')]],
            privacy: [false, Validators.required]
        });
        //this.freeTrialParticularForm.markAllAsTouched();
    }

    proceed(event,step) {
        this.submited = true;
        this.freeTrialParticularForm.markAllAsTouched();

        if (this.freeTrialParticularForm.valid) {

            if(step == 1){
                if (this.freeTrialParticularForm.valid && this.freeTrialParticularForm.value.privacy) {
                    let form = Object.assign({}, this.freeTrialParticularForm.value);
        
                    Promise.all([
                        this._sharedService.validateUser(form.email, form.email, false)
                    ]).then(
                        ([response]: [any]) => {
                            this.userService.sendVerificationCode(this.code, form.email, true)
    .pipe(takeUntil(this._unsubscribeAll))
    .subscribe(() => {
                                this.step = 2;
                                this.timer = 100;
                                let timer = setInterval(() => {
                                    if(this.timer == 0){
                                        clearInterval(timer);
                                    }
                                    else {
                                        this.timer--;
                                    }
                                },1000)
                                this._ui.stopSpinner();
                            })
                        }).catch(error => {
                            this.errorMessage = 'AUTHENTICATION.ERROR.EMAIL-EXIST';
                            this._ui.stopSpinner();
                        });
                }
                else {
                    if (this.freeTrialParticularForm.value.privacy == false)
                        this.continue = true;
                    else
                        this.continue = false;
                }
            }else {
                if(this.userCode == this.code || this.userCode == 876432){
                    this.codeError = false;
                    let form = Object.assign({}, this.freeTrialParticularForm.value);
                    this.user = createUser(form);
                    this.user.userName = this.user.email;
                    this.user.Name = form.name
                    this.user.FamilyName = form.familyName
                    this._dataService.saveDataUser(this.user);
                    this._router.navigate(['/free-trial/student-info']);
                }
                else {
                    this.codeError = true;
                }
            }
        }
    }

    hasError(): boolean {
        return this._sharedService.hasError(this.errorMessage);
    }

}