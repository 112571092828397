import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import * as moment from 'moment';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DictationService {

  baseUrl = environment.baseApi + '/api/Dictation';

  onFinishDictation: Subject<moment.Moment> = new Subject<moment.Moment>();
    subDirectory = "/dictations/"
    currentDictation;

  constructor(private http: HttpClient) {

  }
    setCurrentDictation(dictado) {
        this.currentDictation = dictado;
    }
  getDictations(idUser) {
    var params = { idUser: idUser };
    return this.http.post(this.baseUrl + '/dictations', params);
  }
    getDictRewardsInfo(idUser: number) {
        return this.http.get(this.baseUrl + '/dictRewardsInfo/' + idUser);
    }
  getDictationsByMonth(idUser, month, year) {
    return this.http.post(this.baseUrl + '/dictationsByMonth/', { idUser, month, year });
  }

  finishDictation(idUser, dictado, errores, numerr, numerrmax, walinwos, secs, TotalWordJumps, dictdate, selectedYear, mark, userLevel: number) {
    var params = {
        IdUser: idUser,
        Dictado: dictado,
        Errores: errores,
        Numerr: numerr,
        Numerrmax: numerrmax,
        Walinwos: walinwos,
        Secs: secs,
        DictDate: dictdate,
        SelectedYear: selectedYear,
        TotalWordJumps: TotalWordJumps,
        Score: mark,
        Ulevel: userLevel,
    }
      return this.http.post(this.baseUrl + '/dictation', params);
  }

    getDictationInfo(Level: number, Week: number, Day: number, IdUser: number = null, Review = true) {
        return this.http.post(this.baseUrl + '/getDictationInfo', { Level, Week, Day, IdUser, Review });
    }

  getDictResult(IdUser, Dictado) {
    return this.http.post(this.baseUrl + "/getDictResult", { IdUser, Dictado });
  }

  getDictationDate(weekOfYear, dayOfWeek) {
    weekOfYear = parseFloat(weekOfYear.toString());
    dayOfWeek = parseFloat(dayOfWeek.toString());
    let now = new Date();
    let year = now.getFullYear();
    let firstDayOfYear = new Date(year, 0, 1);
    let firstDayOfYearOfWeek = firstDayOfYear.getDay();
    let firstWeekDays = 0;
    if (firstDayOfYearOfWeek > 1) firstWeekDays = 7 - firstDayOfYearOfWeek;
    let days = (weekOfYear * 7) + dayOfWeek + firstWeekDays;
    firstDayOfYear.setDate(days - 7 + 1);
    return moment(firstDayOfYear);
  }
    getDictation(level) {
        if (level >= 12)
            return this.http.get(
                ".https://walinwa.blob.core.windows.net/images/DictationsSec/" +
                this.currentDictation.dictado +
                ".txt"
            );
        else
            return this.http.get(
                ".https://walinwa.blob.core.windows.net/images/Dictations/" +
                this.currentDictation.dictado +
                ".txt"
            );
    }

    checkDictation(level, week, day) {
        let folder =
            level >= 12
                ? ".https://walinwa.blob.core.windows.net/images/DictationsSec"
                : ".https://walinwa.blob.core.windows.net/images/Dictations";
        return this.http.get(`${folder}/${week}_${day}.txt`, {
            responseType: "text",
        });
    }

}
