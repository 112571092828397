import { ApplicationRef, Injectable, Injector } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SwUpdate } from '@angular/service-worker';
import { FuseDialogContinueComponent } from '@fuse/components/dialog-continue/dialog-continue.component';
import { Observable, Subject } from 'rxjs';
import { filter, first } from 'rxjs/operators';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { LOCATION_INITIALIZED } from '@angular/common';
import { UserService } from './user.service';

@Injectable({
    providedIn: 'root'
})
export class WalinwaUpdateService
{ 
    private _currentDialog: MatDialogRef<any>;
    private _newVersionAvailable: Subject<void>;
    private _newVersionAvailable$: Observable<void>;

    constructor(private _updates: SwUpdate, private _matDialog: MatDialog) {
        this._newVersionAvailable = new Subject<void>();
        this._newVersionAvailable$ = this._newVersionAvailable.asObservable()
    }

    public checkForUpdates(swUpdate: SwUpdate, walinwaUpdateService: WalinwaUpdateService): Promise<void> {
        return new Promise(async (resolve, reject) => {
            try {       
                console.warn("APP_INITIALIZER");
                
                if (swUpdate.isEnabled) {
                    swUpdate.unrecoverable.subscribe(e => {
                        console.log(`Service Worker unrecoverable, ${e.type} ${e.reason}`);
                        setTimeout(_ => location.reload(), 2500);
                    });
                    console.log("swUpdate.isEnabled");
                    await navigator.serviceWorker.ready;
                    console.log("sw ready");
                    if (!navigator.serviceWorker.controller) {
                        console.log("sw controller --> null");
                        setTimeout(_ => location.reload(), 2500);
                        reject();
                    } else {
                        console.log("sw ready with controller");
                        const newVersionAvailable = await swUpdate.checkForUpdate();
                        console.log("newVersionAvailable --> " + newVersionAvailable);
                        if (newVersionAvailable) {
                            const newVersionActivated = await swUpdate.activateUpdate();
                            console.log("newVersionActivated --> " + newVersionActivated);
                            if (newVersionActivated)
                                location.reload();
                            else 
                                walinwaUpdateService.initSWSubscription(swUpdate);
                        }
                    }
                }

                resolve();
    
            } catch (error) {
                console.log("APP_INITIALIZER catch");
                console.log(error);
                setTimeout(_ => location.reload(), 2500);
            }
        });
    }

    public newVersion(): Observable<void> {
        return this._newVersionAvailable$;
    }

    public forceUpdate(): void {
        this._showUpdateDialog();
    }

    public initSWSubscription(_updates: SwUpdate): void {        
        //interval(environment.appCheckForUpdateInterval).subscribe(_ => _updates.checkForUpdate());

        _updates.versionUpdates.pipe(filter(e => e.type === 'VERSION_READY')).subscribe(_ => this._showMustUpdateDialog());
    }
    
    private _showMustUpdateDialog(): void {
        if (this._currentDialog) this._currentDialog.close();
``
        this._currentDialog = this._matDialog.open(FuseConfirmDialogComponent, { disableClose: true });
        this._currentDialog.componentInstance.title = 'Atención';
        this._currentDialog.componentInstance.message1 = 'Existe una nueva versión de Walinwa y es necesario actualizar.';
        this._currentDialog.componentInstance.message2 = 'Pulse "Actualizar" para actualizar ahora o "Postponer" para postponerlo 10 minutos.';
        this._currentDialog.componentInstance.message3 = 'Si lo postpone, se actualizará la aplicación automaticamente a los 10 minutos.';
        this._currentDialog.componentInstance.textButton1 = 'Actualizar';
        this._currentDialog.componentInstance.textButton2 = 'Postponer';
        this._currentDialog.componentInstance.margin = false;

        this._currentDialog.afterClosed().subscribe(confirm => {
            this._currentDialog = null;

            if (confirm)
                this._updates.activateUpdate().then(_ => location.reload()); 
            else
                this._newVersionAvailable.next();            
        });
    }

    private _showUpdateDialog(): void {
        if (this._currentDialog) this._currentDialog.close();

        this._currentDialog = this._matDialog.open(FuseDialogContinueComponent, { disableClose: true });
        this._currentDialog.componentInstance.title = 'Atención';
        this._currentDialog.componentInstance.message1 = 'Existe una nueva versión de Walinwa y se va a actualizar ahora.';
        this._currentDialog.componentInstance.textButton = 'Continuar';
        this._currentDialog.componentInstance.margin = false;

        this._currentDialog.afterClosed().subscribe(_ => {
            this._currentDialog = null;
            this._updates.activateUpdate().then(_ => {
                this.initSWSubscription(this._updates);
                location.reload();
            }); 
        });
    }
}

export function init(
    appRef: ApplicationRef,
    swUpdate: SwUpdate,
    walinwaUpdateService: WalinwaUpdateService,
    translate: TranslateService,
    injector: Injector,
    userService: UserService
  ): () => Promise<void> {
    return async (): Promise<void> => {
        await appRef.isStable.pipe(filter(stable => stable), first());

        const translatePromise = new Promise<void>((resolve, reject) => {
            const locationInitialized = injector.get(LOCATION_INITIALIZED, Promise.resolve(null));

            locationInitialized.then(async () => {
                let currentUser = null;
                //const browserLang = translate.getBrowserLang();
                const languages = (await userService.getLanguages().toPromise()).map(l => l.IdLanguage) || ['es'];
                
                translate.addLangs(languages);

                if (sessionStorage.getItem("currentUser"))
                    currentUser = JSON.parse(sessionStorage.getItem("currentUser"));

                if (!currentUser) {
                    //const available = languages.indexOf(browserLang) > -1;
        
                    //translate.use(available ? browserLang : 'es').subscribe(() => undefined, _ => resolve(), () => resolve());
                    translate.use('es').subscribe(() => undefined, _ => resolve(), () => resolve());
                } else {
                    userService.setApplicationLanguage(currentUser, false).subscribe(() => undefined, _ => resolve(), () => resolve());
                }
            });
        });          

        const updateCheckPromise = walinwaUpdateService.checkForUpdates(swUpdate, walinwaUpdateService);

        return Promise.all([translatePromise, updateCheckPromise]).then(() => undefined);
    };
  }