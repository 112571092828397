import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AvatarFileComponent } from './avatar-file.component';

@NgModule({
  declarations: [AvatarFileComponent],
  imports: [
    CommonModule
  ],
  exports: [
    AvatarFileComponent
  ]
})
export class AvatarFileModule { }
