import { Injectable, inject } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateFn } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Profiles, RouteData } from '../shared/session.model';
import { UserService } from 'app/services/user.service';
import { AuthService } from './auth.service';

@Injectable()
export class AuthGuardService {

    private _helper: JwtHelperService;

    constructor(public userService: UserService, private _router: Router, private _authService: AuthService) {
        this._helper = new JwtHelperService();
    }

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        return this.executeAction(route, state);
    }

    private async executeAction(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        const token: string = sessionStorage.getItem("ngStorage-token");

        if (!token || this._helper.isTokenExpired(token)) {
            this._router.navigate(['login']);

            return false;
        }

        if (this._authService.reconfigureRoutes()) {
            this._router.navigateByUrl(state.url, { replaceUrl: true });

            return false;
        }

        const tokenDecoded = this._helper.decodeToken(token);
        const allowedProfiles = (next.data[RouteData.AllowedProfiles] || []);
        const AsyncFunction = Object.getPrototypeOf(async function () { }).constructor;
        //const allowed = allowedProfiles.length === 0 || await allowedProfiles.some(async profile => typeof profile === 'string' ? tokenDecoded[profile] === 'true' : (tokenDecoded[profile[RouteData.Profile]] === 'true' && (await new AsyncFunction(profile[RouteData.Assert]).bind(this))()));
        const allowed = allowedProfiles.length === 0 || await this._some(allowedProfiles, async (profile) => typeof profile === 'string' ? tokenDecoded[profile] === 'true' : (tokenDecoded[profile[RouteData.Profile]] === 'true' && await new AsyncFunction(profile[RouteData.Assert]).bind(this)()));

        Object.values(Profiles).forEach(profile => sessionStorage.setItem(profile, tokenDecoded[profile]));

        if (!allowed)
            this._router.navigate(['login']);

        return allowed;
    }

    private async _some(profiles, predicate) {

        for (let profile of profiles) {
            if (await predicate(profile)) return true;
        }
        return false;
    };
}

export const canActivateRoute: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => inject(AuthGuardService).canActivate(route, state);