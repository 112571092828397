import { School } from './../../../core/shared/state/models/school.model';
import { Component, OnInit, ViewEncapsulation, HostListener, Renderer2, AfterViewInit, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UiService } from '@fuse/components/spinner/ui.service';

import { SharedService } from 'app/core/shared/shared.service';
import { AuthService } from 'app/services/auth.service';
import { NavigationService } from 'app/services/navigation.service';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { SchoolService } from 'app/services/school.service';
import * as moment from 'moment';
import { AppService } from 'app/app.service';
import { ToolbarService } from 'app/services/toolbarService.service';
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { appRoutes } from 'app/app.module';

@Component({
    selector: "login",
    templateUrl: "./login.component.html",
    styleUrls: ["./login.component.scss"],
    encapsulation: ViewEncapsulation.None,
    host: {
        "(window:resize)": "onResize($event)",
    },
})
export class LoginComponent implements OnInit, AfterViewInit, OnDestroy {
    private _unsubscribeAll: Subject<any>;
    loading = false;
    loginFormStudent: UntypedFormGroup;
    mobile: boolean = false;
    iPad = /iPad|Macintosh/i.test(navigator.userAgent);
    mobileDetect;
    logStudent = false;
    error_student;
    showPassword2 = false;
    constructor(
        private _formBuilder: UntypedFormBuilder,
        private _router: Router,
        public _sharedService: SharedService,
        private authService: AuthService,
        private _ui: UiService,
        private navigationService: NavigationService,
        iconRegistry: MatIconRegistry,
        private schoolService: SchoolService,
        sanitizer: DomSanitizer,
        private appService: AppService,
        private renderer: Renderer2,
        private toolbarService: ToolbarService,
        private _translateService: TranslateService
    ) {
        this._unsubscribeAll = new Subject();
        this._ui.spin$.next(true);
        this.mobileDetect = this.appService.mobileDetect();
        iconRegistry.addSvgIcon('gafas_off', sanitizer.bypassSecurityTrustResourceUrl("https://walinwa.blob.core.windows.net/images/ver-pass-1.svg?ngsw-bypass=true"));
        iconRegistry.addSvgIcon('gafas_on', sanitizer.bypassSecurityTrustResourceUrl("https://walinwa.blob.core.windows.net/images/ver-pass.svg?ngsw-bypass=true"));
    }

    ngOnDestroy(): void
  {
      // Unsubscribe from all subscriptions
      this._unsubscribeAll.next();
      this._unsubscribeAll.complete();
  }

    ngAfterViewInit(){
        const matToolbarRef = this.toolbarService.getToolbarRef();
        if (matToolbarRef) {
            setTimeout(() => {
                this.renderer.removeClass(
                    matToolbarRef.nativeElement,
                    "only-logo"
                );
            }, 0);
        }
    }

    @HostListener("window:resize", ["$event"])
    onResize(event) {
        this.mobile = this._sharedService.isMobile();
    }

    ngOnInit(): void {
        this.showPassword2 = false;
        if (this.authService.currentUser) this.navigationService.goLogin();
        this._router.resetConfig(appRoutes);
        this.mobile = this._sharedService.isMobile();

        this.loginFormStudent = this._formBuilder.group({
            userName: ["", [Validators.required, Validators.maxLength(70)]],
            password: [
                "",
                [
                    Validators.required,
                    Validators.minLength(6),
                    Validators.maxLength(20),
                ],
            ],
        });
    }

    login() {
        this.logStudent = true;
        this.error_student = null;
        if (!this.loginFormStudent.valid) return;
        this.loading = true;
        this._ui.showSpinner();
        this.authService.login(
            this.loginFormStudent.value.userName.trim(),
            this.loginFormStudent.value.password,
            (result) => {
                this.loading = false;
                this._ui.stopSpinner();
                //this.checkVersion();
            },
            (error) => {

                /*if (error.error == "version")
                    this.globalService.versionError = true;*/
                if (typeof error === "string" || error instanceof String) {
                    this.error_student = this._translateService.instant((error || '').toString());
                } else {
                    let errorText = this._sharedService.getError(error);
                    if (errorText == this._translateService.instant("AUTHENTICATION.ERROR.LICENSE")) {
                        if (!error.error.user.Activated) {
                            this.schoolService.getSchoolByUser(error.error.user.Id)
                                .pipe(takeUntil(this._unsubscribeAll))
                                .subscribe((result: School) => {
                                this._ui.stopSpinner();
                                this.loading = false;
                                
                                if (result.Prepaid && moment(result.ValidUntil) > moment()) {
                                    this.navigationService.go("/renew-licence", { IndividualAmount: result.IndividualAmount, ValidUntil: result.ValidUntil });
                                } else {
                                    this.error_student = errorText
                                }
                            }, () => {
                                this.error_student = errorText;
                                this._ui.stopSpinner();
                                this.loading = false;
                            });
                        } else {
                            this.error_student = errorText;
                            this._ui.stopSpinner();
                            this.loading = false;
                        }
                    } else {
                        this.error_student = errorText;
                    }
                }
                if (error?.error?.code != "License") {
                    this._ui.stopSpinner();
                    this.loading = false;
                }
                this.loading = false;
                this._ui.stopSpinner();
            }
        );
    }

    /*checkVersion() {
        this.globalService.versionError = false;
        let meta: any = document.querySelector('meta[name="version"]');
        let localVersion = meta.content;
        this.versionService.getVersion()
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(
            (result: any) => {
                let v = result.Version.split(";")[0];
                let e = result.Version.split(";")[1];
                if (result.Version == "Maintenance") {
                    // Mantenimiento
                } else if (v != localVersion) {
                    this.globalService.versionError = true;
                    let cReload = 1;
                    if (sessionStorage.getItem("cReload")) {
                        cReload = parseFloat(sessionStorage.getItem("cReload"));
                    }
                    if (cReload <= 3) {
                        sessionStorage.setItem("cReload", (cReload + 1).toString());
                        window.location.reload();
                    } else {
                        sessionStorage.removeItem("cReload");
                    }
                } else {
                    sessionStorage.removeItem("cReload");
                }
            });
    }*/

    newPasswordStudent() {
        this.navigationService.go("/request-reset-password", {
            isStudent: true,
        });
    }
}
