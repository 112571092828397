
<div class="main_container" [style.background-image]="'url(&quot;https://walinwa.blob.core.windows.net/images/esquina-inf-der.svg&quot;)'">
    <section fxLayout="column" fxLayoutAlign="stretch" class="box_options">
        <div class="box_op">
            <div class="title">
                <span>{{ 'AUTHENTICATION.FREE-TRIAL.FREE-TRIAL-PARTICULAR.FAMILY-PASSWORD.TITLE' | translate }}</span>
            </div>
            <div class="cont">
                <form class="walinwa-box-content" [formGroup]="familyPasswordForm" autocomplete="off">
                    <div style="margin-bottom: 1rem;">
                        {{'AUTHENTICATION.FREE-TRIAL.FREE-TRIAL-PARTICULAR.FAMILY-PASSWORD.SUBTITLE' | translate}}
                    </div>
                    <div fxLayout="row" fxLayout.lt-md="column" fxNgClass.gt-sm="spe_gap">
                        <div fxFlex="">
                            <label class="font_blue">{{'PASSWORD' | translate}}</label>
                            <mat-form-field class="spe-form-field">
                                <input matInput [type]="hidePass ? 'password' : 'text'" formControlName="password">
                                <i class="icone pass_icon no_mb" [ngClass]="hidePass ? 'eye-close' : 'eye-open'" (click)="hidePass = !hidePass" matSuffix></i>
                                <mat-error *ngIf="familyPasswordForm.get('password').hasError('required')">
                                    {{'FIELD-REQUIRED' | translate}}
                                </mat-error>
                                <mat-error *ngIf="familyPasswordForm.get('password').errors?.minlength">
                                    {{ 'PASSWORD-LENGTH' | translate }}
                                </mat-error>
                                <mat-error *ngIf="familyPasswordForm.get('password').errors?.maxlength">
                                    {{ 'PASSWORD-LENGTH' | translate }}
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <mat-error *ngIf="errorMessage" class="error-message">
                            {{ errorMessage }}
                        </mat-error>
                        <div fxHide fxShow.gt-sm fxFlex="">
                            <!--  -->
                        </div>
                    </div>
                    <div fxLayout="row wrap" fxLayoutAlign="end end" fxLayoutAlign.xs="center center" style="margin-top: 35px !important;">
                        <button mat-raised-button class="submit-button orange fixed_button" aria-label="LOGIN"
                            (click)="submit(familyPasswordForm)">
                            {{ 'SAVE' | translate }}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </section>
</div>