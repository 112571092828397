import { Component, OnInit, ViewEncapsulation, HostListener, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { UiService } from '@fuse/components/spinner/ui.service';

import { SharedService } from 'app/core/shared/shared.service';
import { UserService } from 'app/services/user.service';
import { AuthService } from 'app/services/auth.service';
import { AppService } from 'app/app.service';
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';


@Component({
    selector: 'reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss'],
    encapsulation: ViewEncapsulation.None,
    host: {
        '(window:resize)': 'onResize($event)'
    }
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
    private _unsubscribeAll: Subject<any>;

    mobile: boolean = false;
 iPad = /iPad|Macintosh/i.test(navigator.userAgent); mobileDetect;
    errorMessage: boolean = false;
    resetPasswordForm: UntypedFormGroup;
    isSubmitted = false;
    idUser;
    token;
    showPassword1 = false;
    showPassword2 = false;
    constructor(
        private _formBuilder: UntypedFormBuilder,
        private _router: Router,
        public _sharedService: SharedService,
        private _ui: UiService,
        private _activatedRoute: ActivatedRoute,
        private userService: UserService,
        private authService: AuthService,
        private appService: AppService,
        iconRegistry: MatIconRegistry,
        sanitizer: DomSanitizer,
    ) {
        this._unsubscribeAll = new Subject();
        this._ui.spin$.next(true);
        this.mobileDetect = this.appService.mobileDetect();
        iconRegistry.addSvgIcon('gafas_off', sanitizer.bypassSecurityTrustResourceUrl("https://walinwa.blob.core.windows.net/images/ver-pass-1.svg?ngsw-bypass=true"));
        iconRegistry.addSvgIcon('gafas_on', sanitizer.bypassSecurityTrustResourceUrl("https://walinwa.blob.core.windows.net/images/ver-pass.svg?ngsw-bypass=true"));
    }

    ngOnDestroy() {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
        }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        event.target.innerWidth;
        this.mobile = this._sharedService.isMobile();
    }

    ngOnInit(): void {

        this.mobile = this._sharedService.isMobile();
        this.resetPasswordForm = this._formBuilder.group({
            password1: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(10)]],
            password2: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(10)]]
        }, { validator: this.matchValidator });

        this.idUser = this._activatedRoute.params['_value'].idUser;
        this.token = this._activatedRoute.params['_value'].token;// + "==";
        // if (location.href.includes("resetpasswordintern")) {
        //     this.token = this._activatedRoute.params['_value'].token
        // }
    }

    matchValidator(group: UntypedFormGroup) {

        var valid = false;

        if (group.controls.password1.value == group.controls.password2.value) valid = true;

        if (valid) {
            return null;
        }

        return {
            mismatch: true
        };

    }

    submit(form) {
        this.isSubmitted = true;
            if (this.resetPasswordForm.valid) {
                //console.log(this.token, "CfDJ8L5HWtGXyh1OvJmYwogS0FRLmCbOX6hof_Le2YYc-SxIgLoK0WBtsh1FkZuRt3qTwqo1lg31BwBtCSnvhd_CMKSsJnuHvItMfZiBFoD34l1twWJDaVI2Qvh8xJPJTttJ_92oGc4mBfKvu25Okx--mNWD8GGoVurGCG6c5dB-Ww8h_4IxV-4UKB763h3hn26FOw==")
                this.userService.ResetPassword(this.idUser, this.token, this.resetPasswordForm.value.password1).pipe(takeUntil(this._unsubscribeAll)).subscribe(_ => {
                    this._ui.stopSpinner();
                    this.authService.login(this.idUser, this.resetPasswordForm.value.password1, _ => {
                    }, error => {
                    });
                }, error => {
                    this._ui.stopSpinner()
                    this._router.navigate(['./request-reset-password'])
                });
            }
    }
}
