import { Component, OnInit, ViewEncapsulation, HostListener, Renderer2, AfterViewInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedService } from 'app/core/shared/shared.service';
import { AppService } from 'app/app.service';
import { ToolbarService } from 'app/services/toolbarService.service';

@Component({
    selector: 'free-trial',
    templateUrl: './free-trial.component.html',
    styleUrls: ['./free-trial.component.scss'],
    encapsulation: ViewEncapsulation.None,
    host: {
        '(window:resize)': 'onResize($event)'
    }
})
export class FreeTrialComponent implements OnInit, AfterViewInit {

    loginFormParticular: UntypedFormGroup;
    loginFormEducational: UntypedFormGroup;
    accessLogin: string;
    mobile: boolean = false;
     iPad = /iPad|Macintosh/i.test(navigator.userAgent); mobileDetect;
    errorMessage: string;
    constructor(
        private _router: Router,
        public _sharedService: SharedService,
        private appService: AppService,
        private renderer: Renderer2,
        private toolbarService:ToolbarService,
        private activatedRoute: ActivatedRoute
    ) {
        this.mobileDetect = this.appService.mobileDetect();
    }

    ngAfterViewInit(){

        const matToolbarRef = this.toolbarService.getToolbarRef();
        if (matToolbarRef) {
            setTimeout(() => {
                this.renderer.addClass(matToolbarRef.nativeElement, 'only-logo');
            }, 0);
        }
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        event.target.innerWidth;
        this.mobile = this._sharedService.isMobile();
    }

    ngOnInit(): void {

        this.mobile = this._sharedService.isMobile();
        this.accessLogin = null;
    }

    goParticular(e: Event) {
        e.preventDefault();
        this._router.navigate(['free-trial-particular'], {relativeTo: this.activatedRoute});
    }

    goEducational(e: Event) {
        e.preventDefault();
        this._router.navigate(['free-trial-educational'], {relativeTo: this.activatedRoute});
    }

    showParticular() {
        this.accessLogin = "particular";
    }

    showEducational() {
        this.accessLogin = "educational";
    }

    goBack() {
        this.accessLogin = null;
    }

    getError(error) {
        if (error && error.error) {
            switch (error.error) {
                case "PasswordWrong":
                    this.errorMessage = 'PASSWORD-WRONG';
                    break;
                case "UnregisteredUser":
                    this.errorMessage = 'AUTHENTICATION.ERROR.USER';
                    break;
                default:
                    break;
            }
            alert(this.errorMessage);
        }
    }

}
