import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpSentEvent, HttpEvent, HttpHeaderResponse, HttpResponse, HttpErrorResponse, HttpUserEvent, HttpProgressEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { GlobalService } from '../../services/global.service';
import { FuseDialogContinueComponent } from '@fuse/components/dialog-continue/dialog-continue.component';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class ErrorInterceptorService implements HttpInterceptor {

    errorMessage: string;
    confirmDialog: MatDialogRef<FuseDialogContinueComponent>;

    constructor(private globalService: GlobalService, private _matDialog: MatDialog, private _translateService: TranslateService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {

        return next.handle(request).pipe(
            tap((event: HttpEvent<any>) => {

            }, (err: any) => {
                if (err instanceof HttpErrorResponse) {
                    this.globalService.isSessionStorageSupported = this.globalService.checkIfIsSessionStorageSupported();
                    if (err.error == "InvalidTimeException") {
                        this.confirmDialog = this._matDialog.open(FuseDialogContinueComponent, {
                            disableClose: true
                        });

                        this.confirmDialog.componentInstance.title = "Hora del dispositivo no válida"
                        this.confirmDialog.componentInstance.message1 = "Revisa la fecha y hora de tu dispositivo. No se puede acceder debido a la diferencia horaria entre tu dispositivo y nuestros sistemas.";
                        this.confirmDialog.componentInstance.textButton = this._translateService.instant('ACCEPT');
                        this.confirmDialog.componentInstance.margin = false;

                        this.confirmDialog.afterClosed().subscribe(result => {
                        });
                    }
                }
            })
        );
    }
}
