import { Injectable, Type } from '@angular/core';
import { OpenIdDictToken } from './openiddict-token.component'
import { HttpHeaders } from '@angular/common/http';
import { Route, Router, Routes } from '@angular/router';
import { TeacherComponent } from 'app/main/school/teacher/teacher.component';
import { ParentComponent } from 'app/main/school/parent/parent.component';
import { Profiles } from '../shared/session.model';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable()
export class AuthService {
    private _helper: JwtHelperService;
    
    constructor(private _router: Router) { 
        this._helper = new JwtHelperService();
    }

    // for requesting secure data using json
    authJsonHeaders() {
        let header = new HttpHeaders();
        header = header.append('Content-Type', 'application/json');
        header = header.append('Accept', 'application/json');
        try {
            var token = sessionStorage.getItem("ngStorage-token");
            token = token.substring(1, token.length - 1);
            header = header.append('Authorization', 'Bearer ' + token);
        } catch (e) {

        }
        return header;
    }

    // for requesting secure data from a form post
    authFormHeaders() {
        let header = new HttpHeaders();
        header = header.append('Content-Type', 'application/x-www-form-urlencoded');
        header = header.append('Accept', 'application/json');
        try {
            header = header.append('Authorization', 'Bearer ' + sessionStorage.getItem("ngStorage-token"));
        } catch (e) {

        }
        return header;
    }

    // for requesting unsecured data using json
    jsonHeaders() {
        let header = new HttpHeaders();
        header.append('Content-Type', 'application/json');
        header.append('Accept', 'application/json');
        return header;
    }

    // for requesting unsecured data using form post
    contentHeaders() {
        let credentials = 'Walinwa' + ':' + 'walSecrinwa';
        var headerValue = btoa(credentials);
        let headers = new HttpHeaders();
        headers = headers.append("Access-Control-Allow-Origin", "*");
        headers = headers.append("Access-Control-Allow-Methods", "GET, POST, DELETE, PUT");
        headers = headers.append("Access-Control-Allow-Headers", "X-Requested-With,content-type");
        headers = headers.append("Access-Control-Allow-Credentials", "true");
        headers = headers.append("Content-Type", "application/x-www-form-urlencoded");
        headers = headers.append("Authorization", "Basic" + headerValue);
        return headers;

    }

    // After a successful login, save token data into session storage
    // note: use "localStorage" for persistent, browser-wide logins; "sessionStorage" for per-session storage.
    login(responseData: OpenIdDictToken) {
        try {
            let expired_in = new Date(new Date().getTime() + (1000 * responseData.expires_in)).toISOString();
            sessionStorage.setItem('ngStorage-token', responseData.access_token);
            //this.reconfigureRoutes();
            sessionStorage.setItem('bearer', JSON.stringify(responseData.access_token));
            sessionStorage.setItem('ngStorage-refresh', JSON.stringify(responseData.refresh_token));
            sessionStorage.setItem('ngStorage-expires', JSON.stringify(expired_in));
        } catch (e) {

        }
    }

    // called when logging out user; clears tokens from browser
    logout() {
        try {
            sessionStorage.removeItem('ngStorage-token');
            sessionStorage.removeItem('ngStorage-refresh');
            sessionStorage.removeItem('ngStorage-expires');
            sessionStorage.removeItem('currentUser');
        } catch (e) {

        }
    }

    // simple check of logged in status: if there is a token, we're (probably) logged in.
    // ideally we check status and check token has not expired (server will back us up, if this not done, but it could be cleaner)
    loggedIn(isSessionStorageSupported = true) {
        if (!isSessionStorageSupported) return false;
        if (sessionStorage.getItem("isLogged") == "false") {
            // token dosen´t exist
            return false;
        }

        var now = new Date().getTime();
        var dateExp = new Date(JSON.parse(sessionStorage.getItem("ngStorage-expires")));

        if (now >= dateExp.getTime()) {
            // token expired
            this.logout();
            return false;
        } else {
            return true;
        }
    }

    public reconfigureRoutes(): boolean {    
        const token: string = sessionStorage.getItem("ngStorage-token");
    
        if (token) {
          const tokenDecoded = this._helper.decodeToken(token);
          let config: Routes = this._router.config.map(r => Object.assign({}, r));;

          if (tokenDecoded[Profiles.IsTeacher] === 'true') {
            const _previousConfig = JSON.stringify(config);
            config = this._isRoutesForType<ParentComponent>(ParentComponent, config);
            const currentConfig = JSON.stringify(config);

            if (_previousConfig !== currentConfig) {
                this._router.resetConfig(config);  

                return true;
            }
  
          } else if (tokenDecoded[Profiles.IsParent] === 'true') {
            const _previousConfig = JSON.stringify(config);
            config = this._isRoutesForType<TeacherComponent>(TeacherComponent, config);
            const currentConfig = JSON.stringify(config);

            if (_previousConfig !== currentConfig) {
                this._router.resetConfig(config);

                return true;
            }
          }
        }

        return false;
    }

    private _isRoutesForType<T>(type: Type<T>, routes: Route[]) {
        return routes.filter(route => {
            if (route.data?.entryComponent === type)
                return false;
    
            if (Array.isArray(route.children)) 
                route.children = this._isRoutesForType(type, route.children);
    
            if (Array.isArray(route['_loadedRoutes']))
                route['_loadedRoutes'] = this._isRoutesForType(type, route['_loadedRoutes']);
    
            return true;
        });
    }
}