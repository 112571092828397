import { SchoolService } from './school.service';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { FuseDialogContinueComponent } from '@fuse/components/dialog-continue/dialog-continue.component';
import { TranslateService } from '@ngx-translate/core';
declare var $;

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  backButton = false;
  exitButton = false;
  stateExam = 'classes-exams';
  stateControl = 'classes-controlSessions';
  StateTest = 'classes-personalizedTests';
  messageState = 'viewMessage'
  add_class_step
  params: any = {};

  constructor(
    private router: Router,
    private matDialog: MatDialog,
    private schoolService: SchoolService,
    private _translateService: TranslateService) {
    this.reset();
  }

  reset() {
    this.backButton = false;
    this.exitButton = false;
    this.back = () => { }
  }

  resetParams() {
    this.params = {};
  }

  enableBackButton(enable = true, callback = () => { }) {
    setTimeout(() => {
      this.reset();
      this.backButton = enable;
      this.back = callback;
    }, 0);
  }

  setStateExam(status: string){
    this.stateExam = status;
  }

  setStateControl(status: string){
    this.stateControl = status;
  }
  setStateTest(status: string){
    this.StateTest = status;
  }
  setStateMessage(status: string){
    this.messageState = status;
  }

  enableExitButton(enable = true) {
    setTimeout(() => {
      this.reset();
      this.exitButton = enable;
    }, 0);
  }
  enableBackButtonExam(enable = true, state: string) {
    setTimeout(() => {
      this.reset();
      this.backButton = enable;
      this.stateExam = state;
    }, 0);
  }
  enableBackButtonEControl(enable = true, state: string) {
    setTimeout(() => {
      this.reset();
      this.backButton = enable;
      this.stateControl = state;
    }, 0);
  }
  enableBackButtonTest(enable = true, state: string) {
    setTimeout(() => {
      this.reset();
      this.backButton = enable;
      this.StateTest = state;
    }, 0);
  }
  enableBackButtonMessage(enable = true, state: string) {
    setTimeout(() => {
      this.reset();
      this.backButton = enable;
      this.messageState = state;
    }, 0);
  }
  go(route=null, params = {}) {
    console.log(route);
    if (route) { 
      this.router.navigateByUrl(route)
      // this.router.navigateByUrl('/', { 
      //   skipLocationChange: true 
      // }).then(() => this.router.navigate([route], { queryParams: { ...params } }),);
    }
    this.params = params;
  }

  isJS() {
    return $('#mainWindow').hasClass("show-frame");
  }


 backAll(){
    if(this.params?.isAdmin  || this.StateTest == 'viewReport'){
        this.go("/school/admin")
    }
  if (this.stateExam == 'classes-exams' && this.stateControl =='classes-controlSessions' && (this.StateTest == 'classes-personalizedTests') && (this.messageState == 'newMessage' ||this.messageState == 'suggestionsMessage' ||this.messageState == 'viewMessage' )) {
    this.back();
  }else{
    this.backExam();
  }
 }

 back(){

 }

 backExam(){
    this.messageState = 'viewMessage'
  if((this.stateExam.includes('add-class-') || this.stateControl.includes('add-class-') || this.StateTest.includes('add-class-')) && this.add_class_step != -1 && this.add_class_step > 1){
      this.add_class_step--;
      return;
  }
  this.stateExam = 'classes-exams';
  this.stateControl = 'classes-controlSessions';
  if (this.StateTest == 'add-personalizedTestQuestions' ) {
    this.StateTest = 'add-class-personalizedTest'
  }else {
    this.StateTest = 'classes-personalizedTests'
    this.exitButton = true;
    this.backButton = false;
  }
  
 }

  exit(ask = false) {
    if (ask) {
      let confirmDialog = this.matDialog.open(FuseDialogContinueComponent, {
        disableClose: true
      });
      confirmDialog.componentInstance.title = "Abandonar aplicación";
      confirmDialog.componentInstance.theme = "white";
      confirmDialog.componentInstance.margin = false;
      confirmDialog.componentInstance.options = [{
        text: "Sí",
        callback: () => { this.goLogin(); }
      }, {
        text: "No",
        callback: () => { }
      }];
      confirmDialog.componentInstance.margin = false;
      confirmDialog.afterClosed().subscribe(result => {
        result();
        confirmDialog = null;
      });
    } else {
      this.goLogin();
    }
  }

  question(message, options) {
    let confirmDialog = this.matDialog.open(FuseDialogContinueComponent, {
      disableClose: true
    });
    confirmDialog.componentInstance.title = message[0];
    if (message[1]) confirmDialog.componentInstance.message1 = message[1];
    if (message[2]) confirmDialog.componentInstance.message2 = message[2];
    if (message[3]) confirmDialog.componentInstance.message3 = message[3];
    confirmDialog.componentInstance.theme = "white";
    confirmDialog.componentInstance.margin = true;
    confirmDialog.componentInstance.options = options;
    confirmDialog.afterClosed().subscribe(result => {
      result();
      confirmDialog = null;
    });
  }

  error(message, ko, execResult = false) {
    let confirmDialog = this.matDialog.open(FuseDialogContinueComponent, {
      disableClose: true
    });
    confirmDialog.componentInstance.title = message[0];
    message.splice(0, 1)
    confirmDialog.componentInstance.messages = message
    confirmDialog.componentInstance.theme = "white";
    confirmDialog.componentInstance.margin = false;
    confirmDialog.componentInstance.options = [
      {
        text: this._translateService.instant('ACCEPT'),
        callback: ko
      }
    ];
    confirmDialog.componentInstance.margin = false;
    confirmDialog.afterClosed().subscribe(result => {
      if(execResult){
        result();
      }
      confirmDialog = null;
    });
  }

  warn(message, ok, ko) {
    let confirmDialog = this.matDialog.open(FuseDialogContinueComponent, {
      disableClose: true
    });
    confirmDialog.componentInstance.title = message[0];
    if (message[1]) confirmDialog.componentInstance.message1 = message[1];
    if (message[2]) confirmDialog.componentInstance.message2 = message[2];
    if (message[3]) confirmDialog.componentInstance.message3 = message[3];
    confirmDialog.componentInstance.theme = "white";
    confirmDialog.componentInstance.margin = false;
    confirmDialog.componentInstance.options = [
      {
        text: this._translateService.instant('ACCEPT'),
        callback: ok
      },
      {
        text: this._translateService.instant('CANCEL'),
        callback: ko
      }
    ];
    confirmDialog.componentInstance.margin = false;
    confirmDialog.afterClosed().subscribe(result => {
      result();
      confirmDialog = null;
    });
  }
    PlayAndZoneMissingConditions(title, ok, ko) {
        let confirmDialog = this.matDialog.open(FuseDialogContinueComponent, {
            disableClose: true
        });
        confirmDialog.componentInstance.title = title;
        confirmDialog.componentInstance.message1 = this._translateService.instant("STUDENT.ZONE.MODAL-MESSAGE");
        confirmDialog.componentInstance.theme = "white";
        confirmDialog.componentInstance.margin = false;
        confirmDialog.componentInstance.options = [
            {
                text: this._translateService.instant("STUDENT.ZONE.SEE-DETAILS-BUTTON"),
                callback: ko
            },
            {
                text: this._translateService.instant('ACCEPT'),
                callback: ok
            }
        ];
        confirmDialog.componentInstance.margin = false;
        confirmDialog.afterClosed().subscribe(result => {
            result();
            confirmDialog = null;
        });
    }
    BasicModal(title = "", message: string[] = [], theme = "blue", textButton = this._translateService.instant('ACCEPT'), margin = false) {
        console.log("entro")
        let confirmDialog = this.matDialog.open(FuseDialogContinueComponent, {
            disableClose: true
        });
        confirmDialog.componentInstance.title = title;
        if (message.length > 0) {
            message.forEach((message, index) => {
                index++;
                confirmDialog.componentInstance["message" + index] = message;
            })
        }
        confirmDialog.componentInstance.textButton = textButton
        confirmDialog.componentInstance.margin = margin;
        confirmDialog.componentInstance.theme = theme
        confirmDialog.afterClosed().subscribe(result => {
            confirmDialog = null;
        });
        return;
    }

  goLogin() {
    this.reset();
    sessionStorage.removeItem('demandData');
    sessionStorage.removeItem('currentUser');
    sessionStorage.removeItem('adminLogin');
    sessionStorage.removeItem("state");
    sessionStorage.removeItem("state_params");
    sessionStorage.setItem("isLogged", "false");
    this.schoolService.school = null;
    this.router.navigateByUrl("login");
  }

}
