import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';

import { FuseSharedModule } from '@fuse/shared.module';

import { InitialRegistrationComponent } from './initial-registration.component';
import { NewStudentComponent } from './new-student/new-student.component';
import { FooterAuthModule } from '../footer-auth/footer-auth.module';
import { ComponentsModule } from 'app/components/components.module';

const routes = [
    { path: '', component: InitialRegistrationComponent },
    { path: 'new-student', component: NewStudentComponent },

];

@NgModule({
    declarations: [
        InitialRegistrationComponent,
        NewStudentComponent
    ],
    imports: [
        RouterModule.forChild(routes),
        TranslateModule.forChild(),
        MatButtonModule,
        MatCheckboxModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        FooterAuthModule,
        ComponentsModule,
        FuseSharedModule
    ],
    exports: [RouterModule]
})
export class InitialRegistrationModule {
}