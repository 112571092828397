import { Inject, Injectable, PLATFORM_ID, Renderer2, RendererFactory2 } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { isPlatformBrowser, Location } from '@angular/common';
import { DeviceDetectorService } from 'ngx-device-detector';

import { RepositoryService } from '../repository/repository.service';
import { environment } from '../../../environments/environment';
import { AppService } from 'app/app.service';
import { UiService } from '@fuse/components/spinner/ui.service';
import { IUserInfo } from 'app/main/authentication/user-info';
import { AccountService } from 'app/main/authentication/account.service';

import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NavigationService } from 'app/services/navigation.service';
import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs';
import { AuthService } from '../guards/auth.service';
import { UserService } from 'app/services/user.service';
import { DomSanitizer } from '@angular/platform-browser';

import { environment as devEnvironment } from 'environments/environment.dev';
import { environment as prodEnvironment } from 'environments/environment.prod';
import { TranslateService } from '@ngx-translate/core';

declare var $;

@Injectable()
export class SharedService {
    paying = false;

    private s_reload_user_coins = new BehaviorSubject<boolean>(false);
    reload_user_coins = this.s_reload_user_coins.asObservable();

    private s_school_has_classes = new Subject<boolean>();
    school_has_classes = this.s_school_has_classes.asObservable();
    
    private s_exists_authorized_students_by_parent = new Subject<boolean>();
    exists_authorized_students_by_parent = this.s_exists_authorized_students_by_parent.asObservable();

    private s_custom_session_has_test = new BehaviorSubject<number>(null);
    custom_session_has_test = this.s_custom_session_has_test.asObservable();

    private s_num_students = new Subject<Number>();
    num_students = this.s_num_students.asObservable();

    private s_max_num_students = new Subject<Number>();
    max_num_students = this.s_max_num_students.asObservable();

    private s_school_valid_until = new Subject<Date>();
    school_valid_until = this.s_school_valid_until.asObservable();

    private s_run_get_school = new BehaviorSubject<boolean>(false);
    run_get_school = this.s_run_get_school.asObservable();

    private s_reload_student = new BehaviorSubject<boolean>(false);
    reload_student = this.s_reload_student.asObservable();

    private s_reload_teacher = new Subject<any>();
    reload_teacher = this.s_reload_teacher.asObservable();

    private s_reading_text_enabled = new ReplaySubject<boolean>(1);
    reading_text_enabled = this.s_reading_text_enabled.asObservable();

    private s_deduction_text_enabled = new ReplaySubject<boolean>(1);
    deduction_text_enabled = this.s_deduction_text_enabled.asObservable();

    private s_hearing_text_enabled = new ReplaySubject<boolean>(1);
    hearing_text_enabled = this.s_hearing_text_enabled.asObservable();

    private s_dictation_enabled = new Subject<boolean>()
    dictation_enabled = this.s_dictation_enabled.asObservable()

    private s_personalized_test_enabled = new BehaviorSubject<boolean>(false)
    personalized_test_enabled = this.s_personalized_test_enabled.asObservable()

    private renderer: Renderer2;

    adminInfoForm: UntypedFormGroup = null;
    constructor(private http: HttpClient,
        private _repositoryService: RepositoryService,
        private _appService: AppService,
        private _ui: UiService,
        private _accountService: AccountService,
        private _authService: AuthService,
        private location: Location,
        private _router: Router,
        private _deviceService: DeviceDetectorService,
        private navigationService: NavigationService,
        private activatedRoute: ActivatedRoute,
        private userService: UserService,
        private sanitizer: DomSanitizer,
        private rendererFactory: RendererFactory2,
        private _translateService: TranslateService,
        @Inject(PLATFORM_ID) private platformId: Object,
    ) {
        this._ui.spin$.next(true);

        if (!environment.production) {
            this.renderer = this.rendererFactory.createRenderer(null, null);
            this._modifyImageUrls();
        }
    }

    private _modifyImageUrls() {
        if (isPlatformBrowser(this.platformId)) {
            window.addEventListener('load', () => this._redirectImageUrls());

            const observer = new MutationObserver(() => this._redirectImageUrls());

            observer.observe(document.body, {
            childList: true,
            subtree: true
            });
        }
    }

    private _redirectImageUrls() {
        const images = document.querySelectorAll('img');
        const elements = document.querySelectorAll('div');

        images.forEach((img: HTMLImageElement) => {
            if (img.src && img.src.includes(prodEnvironment.filesUrl)) 
                img.src = img.src.replace(prodEnvironment.filesUrl, devEnvironment.filesUrl);
        });

        elements.forEach((element: HTMLElement) => {
            const backgroundImage = window.getComputedStyle(element).backgroundImage;

            if (backgroundImage && backgroundImage.startsWith('url'))
                this.renderer.setStyle(element, 'background-image', backgroundImage.replace(prodEnvironment.filesUrl, devEnvironment.filesUrl));
        });
    }

    changeDictationEnabled(dictation_enabled: boolean) {
        this.s_dictation_enabled.next(dictation_enabled);
    }
    changeReadingTextEnabled(reading_text_enabled: boolean) {
        this.s_reading_text_enabled.next(reading_text_enabled);
    }
    changeDeductionTextEnabled(deduction_text_enabled: boolean) {
        this.s_deduction_text_enabled.next(deduction_text_enabled);
    }
    changeHearingTextEnabled(hearing_text_enabled: boolean) {
        this.s_hearing_text_enabled.next(hearing_text_enabled);
    }
    changePersonalizedTestEnabled(personalized_test_enabled: boolean){
        this.s_personalized_test_enabled.next(personalized_test_enabled)
    }
    changeReloadStudent(reload_student: boolean) {
        this.s_reload_student.next(reload_student);
    }

    changeReloadTeacher(reload_teacher: any) {
        sessionStorage.setItem(
            "currentUser",
            JSON.stringify(reload_teacher)
        );
        this.s_reload_teacher.next(reload_teacher);
    }

    changeRunGetSchool(run_get_school: boolean){
        this.s_run_get_school.next(run_get_school);
    }

    changeSchoolValidUntil(school_valid_until: Date){
        this.s_school_valid_until.next(school_valid_until);
    }

    changeMaxNumStudents(max_num_students: Number){
        this.s_max_num_students.next(max_num_students);
    }

    changeNumStudents(num_students: Number){
        console.log("num_students", num_students);
        this.s_num_students.next(num_students);
    }

    changeUserCoins(user_coins: boolean){
        this.s_reload_user_coins.next(user_coins);
    }
    changeExistsAuthorizedStudentsByParent(exists_authorized_students_by_parent:boolean){
        this.s_exists_authorized_students_by_parent.next(exists_authorized_students_by_parent);
    }

    ChangeCustomSessionHastTest(custom_session_has_test: number){
        this.s_custom_session_has_test.next(custom_session_has_test);
    }

    changeSchoolHasClasses(school_has_classes:boolean){
        this.s_school_has_classes.next(school_has_classes);
    }

    getCurrentUser() {
        return sessionStorage.getItem("currentUser")
    }

    isMobile() {
        let isDesktop = !(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
        if (isDesktop) return false;
        let mobileMaxWidth = 620;
        let w = window.innerWidth;
        let h = window.innerHeight;
        let android = /Android/i.test(navigator.userAgent);
        let anyFocus = $("input").is(":focus") || $("select").is(":focus");
        let portrait = h > w;
        let landscape = !portrait;
        if (android && anyFocus && portrait) h *= 2;
        if (android && anyFocus && landscape) h *= 5;
        return (w <= mobileMaxWidth && portrait) || (h <= mobileMaxWidth && landscape);
    }


    fullScreen() {
        var iPad = /iPad/i.test(navigator.userAgent);
        if (iPad)
            return;
        var frameElm = window.frameElement;
        var docElm;
        if (frameElm != null)
            docElm = frameElm;
        else
            docElm = document.documentElement;
        if (docElm.webkitRequestFullscreen) {
            docElm.webkitRequestFullscreen(Element['ALLOW_KEYBOARD_INPUT']);
        } else {
            if (docElm.mozRequestFullScreen) {
                docElm.mozRequestFullScreen();
            } else {
                if (docElm.requestFullscreen) docElm.requestFullscreen();
            }
        }
        // var frameElm = window.frameElement;
        // var docElm;
        // if (frameElm != null)
        //     docElm = frameElm;
        // else
        //     docElm = document.documentElement;
        // if (docElm.webkitRequestFullscreen) {
        //      docElm.webkitRequestFullscreen();
        // } else {
        //     if (docElm.mozRequestFullScreen) {
        //         docElm.mozRequestFullScreen();
        //     } else {
        //         if (docElm.requestFullscreen) docElm.requestFullscreen();
        //     }
        // }
    }

    redirectRol(user, redirect = null, userType = 2) {
        switch (user.IdRole) {
            case 1:
                break;
            case 2:
                user.IsTutor = true;
                break;
            case 3:
                user.IsAdmin = true;
                break;
            case 4:
                user.IsAdmin = true;
                user.OnDemand = true;
                break;
            case 5:
                user.IsTutor = true;
                user.IsAdmin = true;
                break;
            case 6:
                user.IsTutor = true;
                user.IsSchoolAdmin = true;
                break;
        }

        try {
            let token = sessionStorage.getItem("ngStorage-token");
            if (token.substring(0, 1) != '"') token = JSON.stringify(token);
            sessionStorage.setItem('currentUser', JSON.stringify(user));
            sessionStorage.setItem('adminLogin', JSON.stringify(user["AdminLogin"]));
            sessionStorage.setItem('isLogged', "true");
            sessionStorage.setItem('ngStorage-token', token);
            //this._authService.reconfigureRoutes();

        } catch (e) {

        }

        if (userType == 1) {
            if (user.IsTutor) {
                if (user.IdSchool != null) {
                    return this.navigationService.go("/school/students", {});
                } else {
                    return this.navigationService.go("/school/parentStudents", {});
                }
            } else if (user.IsAdmin) {
                return this.navigationService.go("/school/admin", {});
            } else {
                return this.navigationService.go("/login", {});
            }
        } else {
            return this.navigationService.go("/student/session", {});
        }

    }

    json(body): any {
        if (typeof body === 'string') {
            return JSON.parse(<string>body);
        }
        return body;
    }

    getError(error) {
        if (error && error.error) {
            console.log(this.isJSON(error.error))
            if (this.isJSON(error.error)) {
                let errCase = null;
                for (let err in error.error) {
                    errCase = error.error[err];
                    break;
                }
                switch (errCase) {
                    case "Code":
                        return this._translateService.instant('AUTHENTICATION.ERROR.CODE');
                    case "License":
                        return this._translateService.instant('AUTHENTICATION.ERROR.LICENSE');
                    case "Email":
                        return this._translateService.instant('AUTHENTICATION.ERROR.EMAIL');
                    case "Username":
                        return this._translateService.instant('AUTHENTICATION.ERROR.USERNAME');
                    case "invalid_grant":
                        return this._translateService.instant('AUTHENTICATION.ERROR.USER');
                    case "invalid_pass":
                        return this._translateService.instant("PASSWORD-WRONG");
                    case "LicenseNotActive":
                        return this._translateService.instant("AUTHENTICATION.ERROR.LICENSE-NOT-ACTIVE")
                    default:
                        return this._translateService.instant("ERROR");
                }
            } else {
                switch (error.error) {
                    case "Code":
                        return this._translateService.instant('AUTHENTICATION.ERROR.CODE');
                    case "License":
                        return this._translateService.instant('AUTHENTICATION.ERROR.LICENSE');
                    case "Email":
                        return this._translateService.instant('AUTHENTICATION.ERROR.EMAIL-EXIST');
                    case "Username":
                        return this._translateService.instant('AUTHENTICATION.ERROR.USERNAME');
                    case "LicenseNotActive":
                        return this._translateService.instant("AUTHENTICATION.ERROR.LICENSE-NOT-ACTIVE")
                    default:
                        switch (error.error.error) {
                            case "invalid_grant":
                                return this._translateService.instant('AUTHENTICATION.ERROR.INVALID_GRANT');
                            case "invalid_pass":
                                return this._translateService.instant("PASSWORD-WRONG");
                            default:
                                return this._translateService.instant("ERROR");
                        }
                }
            }
        } else {
            return this._translateService.instant("AUTHENTICATION.ERROR.CON");
        }
    }

    isJSON(str) {
        if(typeof str == 'string') return false
        try {
            let keys = Object.keys(str);
            if (keys.length != 0) return true;
            else return false;
        } catch (e) {
            return false;
        }
    }

    hasError(errorMessage): boolean {
        if (errorMessage != null || errorMessage != "" || errorMessage != undefined)
            return true
        else
            return false
    }

    validateUser(userName, email, school) {
        let url = environment.baseApi + '/api/User/validate/' + userName + '/' + email + '/' + school;
        this._ui.showSpinner();
        return new Promise((resolve, reject) => {
            this._repositoryService.get(url).subscribe((response: any) => {
                resolve(response);
            },
                error => {
                    this._ui.stopSpinner();
                    reject(error);
                });
        });
    }

    login(loginFormTutor: UntypedFormGroup, redirect = null, userType=2) {
        let userInfo: IUserInfo = Object.assign({}, loginFormTutor.value);
        userInfo.offset = (-(new Date().getTimezoneOffset() / 60)).toString();
        userInfo.userAgent = window.navigator.userAgent;
        userInfo.version = environment.version;
        this._ui.showSpinner();
        this._accountService.login(userInfo)
            .subscribe(response => {
                this._authService.login(this.json(response));

                this.userService
                .getUserByToken()
                .subscribe(
                    (result: any) => {
                        this.redirectRol(result, redirect, userType);
                        this._ui.stopSpinner();
                    },
                    (error) => {
                        this.getError(error);
                    this._ui.stopSpinner();
                    }
                );
            },
                error => {
                    this.getError(error);
                    this._ui.stopSpinner();
                });
    }

    
    public decodeJSONResponse(text): any {
        return JSON.parse(decodeURIComponent(Array.prototype.map.call(atob(text || null), (c) => {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
        }).join('')).replace('WalinwaEncoded', ''));
    }
}