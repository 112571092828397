<div class="main_container" [style.background-image]="'url(&quot;https://walinwa.blob.core.windows.net/images/esquina-inf-der.svg&quot;)'">
    <section fxLayout="column" fxLayoutAlign="stretch" class="box_options">
        <div class="box_op">
            <div class="title">
                <span>{{'AUTHENTICATION.INITIAL-REGISTRATION.NEW-STUDENT.TITLE' | translate}} {{title}}</span>
            </div>
            <div class="cont">
                <form class="walinwa-box-content" [formGroup]="finishRegistrationForm" autocomplete="off">
                    <div fxLayout="row" fxLayout.lt-md="column" fxNgClass.gt-sm="spe_gap">
                        <div fxFlex="">
                            <label class="font_blue">{{ 'NAME' | translate }}</label>
                            <mat-form-field class="spe-form-field">
                                <input matInput type="text" class="" formControlName="name">
                                <mat-error *ngIf="finishRegistrationForm.get('name').hasError('required')">
                                    {{ 'FIELD-REQUIRED' | translate }}
                                </mat-error>
                                <mat-error *ngIf="finishRegistrationForm.get('name').hasError('pattern')">
                                    {{ 'PATTERN-ERROR' | translate }}
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div fxFlex="">
                            <label class="font_blue">{{ 'FAMILYNAME' | translate }}</label>
                            <mat-form-field class="spe-form-field">
                                <input matInput type="text" class="" formControlName="familyName">
                                <mat-error *ngIf="finishRegistrationForm.get('familyName').hasError('required')">
                                    {{ 'FIELD-REQUIRED' | translate }}
                                </mat-error>
                                <mat-error *ngIf="finishRegistrationForm.get('familyName').hasError('pattern')">
                                    {{ 'PATTERN-ERROR' | translate }}
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div *ngIf="errorMessage" class="error-message">
                        <mat-error>
                            {{ errorMessage }}
                        </mat-error>
                    </div>
                    <div fxLayoutAlign="end end" fxLayoutAlign.xs="center center" style="margin-top: 35px !important;">
                        <button *ngIf="!prepaid"  [disabled]="loading" mat-raised-button color="accent" class="submit-button orange fixed_button" 
                            (click)="finishRegistration(finishRegistrationForm)">
                            {{ 'FINISH' | translate }}
                        </button>

                        <button *ngIf="prepaid" [disabled]="loading" mat-raised-button color="accent" class="submit-button orange fixed_button"  
                            (click)="finishRegistration(finishRegistrationForm)">
                            {{ 'AUTHENTICATION.INITIAL-REGISTRATION.NEW-STUDENT.SUBMIT-PAY' | translate }}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </section>
</div>
<tpv [config]="tpvInfo" (onClose)="goLogin()" *ngIf="tpvInfo"></tpv>