import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { AssignedTest } from 'app/core/shared/state/models/personalized-test/assigned-test.model';

@Injectable()
export class CustomizedSessionService {
  themes = []
  baseUrl = environment.baseApi + '/api/CustomizedSession';

  constructor(private http: HttpClient) {
  }

  getMonth(idUser, month) {
    return this.http.get(this.baseUrl + '/idUserTutor/' + idUser + '/month/' + month);
  };

  getThemes() {
    return this.http.get(this.baseUrl + '/getThemes');
  };
  update(obj) {
    var params = {
        IdTest: obj.IdTest,
        IdClass: obj.IdClass,
        Date: obj.Date,
        TestOpen: obj.TestOpen,
    }
    return this.http.put(this.baseUrl + "/activateReview", params);
  }
  add(list) {
    return this.http.post(this.baseUrl + "/add", list);
  }
  hasTest(idClass: number, IdUser: number, UserTime: string){
    var params = {
        IdClass: idClass,
        IdUser: IdUser,
        UserTime: UserTime,
    }
    return this.http.post<number>(this.baseUrl + '/checkTest', params);
  }
  IsTestAssigned(IdTest: number){
    return this.http.get<AssignedTest[]>(this.baseUrl + "/TestAssigned/" + IdTest)
  }
  getAllByUser(idUser){
    return this.http.get(this.baseUrl + '/all/idUser/' + idUser);
  }
  canStartActivity(activity: string){
    return this.http.get(this.baseUrl + "/canStartActivity/" + activity)
  }

}
