import { Component, OnInit, Input, Output, EventEmitter  } from '@angular/core';

@Component({
  selector: 'tpv',
  templateUrl: './tpv.component.html',
  styleUrls: ['./tpv.component.scss']
})
export class TpvComponent implements OnInit {

  @Input() config;

  @Output() onClose = new EventEmitter<string>();

  constructor() { }

  ngOnInit() {
    setTimeout(() => {
      console.log(this.config)
      let tpvForm: any = document.getElementById("tpvForm");
      tpvForm.submit();
    }, 1000);
  }

  close() {
    this.onClose.emit();
  }

}
