import { Class } from './class.model'
import { Course } from './course.model'
import { School } from './school.model'
import { Exam } from './exam.model'
import { MedalsUsers } from './medals-users.model'
import { Session } from './session.model'
import { Civilization } from './civilization.model'
import { ShopItemUser } from './shop-item-user.model'
import { AnnouncementUser } from './announcement-user.model'
import { InvitacionReto } from './invitacion-reto.model'
import { HistoryStage } from './history-stage.model'
import { Reto } from './reto.model'
import { CoinTransactions } from './coin-transactions.model'
import { AuthorizedUser } from './authorized-user.model'
import { Dictation } from './dictation.model'
import { ShopPresent } from './shop-present.model'
import { AdminMessageUser } from './admin-message-user.model'
import { StudentMessage } from './student-message.model'
import { DailyTextScore } from './daily-text-score.model'
import { LicenseTypeUser } from './license-type-user.model'
import { Payment } from './payment.model'
import { EmailHistory } from './email-history.model'
import { GameScore } from './game-score.model';
import { AuthorizedClass } from './autorized-class.model'
import { UserLogin } from './user-login.model'
import { TeacherMessage } from './teacher-message.model'
import { TeacherMessageUser } from './teacher-meesage-user.model'

export interface User {
    userName: string,
    email: string,
    Level: number,
    CreationDate: Date,
    Name: string,
    FamilyName: string,
    DateOfBirth: Date,
    Age: number
    IdCivilizacion: number,
    IdUserTutor: number,
    TutorialDone: boolean,
    IdClass: number,
    IdSchool: number,
    DefaultPassword: boolean,
    Activated: boolean,
    IdRole: string,
    IdReto: number,
    Course: Course,
    Tutor: User,
    Students: User[],
    Sessions: Session[],
    ClientTransactions?: CoinTransactions[],
    Civilization: Civilization,
    ShopItems: ShopItemUser[],
    Licenses: LicenseTypeUser[],
    EmailHistory: EmailHistory[],
    Payments: Payment[],
    Class: Class,
    Classes: Class[],
    GameScores: GameScore[],
    AuthorizedStudents: AuthorizedUser[],
    AuthorizedParents: AuthorizedUser[],
    AuthorizedClasses?: AuthorizedClass[],
    Exams: Exam[],
    DailyTextScores: DailyTextScore[],
    SchoolInfo: School,
    StudentMessages: StudentMessage[],
    AdminMessages: AdminMessageUser[],
    Announcements: AnnouncementUser[],
    Medals: MedalsUsers[],
    Dictations: Dictation[],
    ShopPresents_enviados: ShopPresent[],
    ShopPresents_recibidos: ShopPresent[],
    ShopPresents_nuevos: ShopPresent[],
    SchoolName: string,
    Alias: string,
    EsAdmin: boolean,
    BrothersCount?: string,
    _coins: number,
    LastLogin?: UserLogin,
    Coins: number,
    FullName: string,
    _validUntil?: Date,
    ValidUntil?: Date,
    _validFrom?: Date,
    ValidFrom?: Date,
    IdHistoryStage: string,
    MultTime: number,
    SmallSession: boolean,
    SessionReports: string,
    WarningNoSession: string,
    nomail: boolean,
    HistoryStage: HistoryStage,
    LastRewardDate?: Date,
    BlockName?: boolean,
    BlockContact?: boolean,
    BlockAlias?: boolean,
    RetosEnviados: Reto[],
    RetosRecibidos: InvitacionReto[],
    GiftShopDisabled?: boolean,
    BlockMessageGift?: boolean,
    AvatarDisabled?: boolean,
    Prepaid?: boolean,
    SentTeacherMessages?: TeacherMessage[],
    ReceivedTeacherMessages?: TeacherMessageUser[],
    AspNetUserLanguages: AspNetUserLanguages
}

export interface AspNetUserLanguages {
    UserIdLanguage: string;
    UserStudentsIdLanguage: string;
}

export function createUser(user: User): User {
    return {
        userName: user.userName,
        email: user.email,
        Level: user.Level,
        CreationDate: user.CreationDate,
        Name: user.Name,
        FamilyName: user.FamilyName,
        DateOfBirth: user.DateOfBirth,
        Age: user.Age,
        IdCivilizacion: user.IdCivilizacion,
        IdUserTutor: user.IdUserTutor,
        TutorialDone: user.TutorialDone,
        IdClass: user.IdClass,
        IdSchool: user.IdSchool,
        DefaultPassword: user.DefaultPassword,
        Activated: user.Activated,
        IdRole: user.IdRole,
        IdReto: user.IdReto,
        Course: user.Course,
        Tutor: user.Tutor,
        Students: user.Students,
        Sessions: user.Sessions,
        ClientTransactions: user.ClientTransactions,
        Civilization: user.Civilization,
        ShopItems: user.ShopItems,
        Licenses: user.Licenses,
        EmailHistory: user.EmailHistory,
        Payments: user.Payments,
        Class: user.Class,
        Classes: user.Classes,
        GameScores: user.GameScores,
        AuthorizedStudents: user.AuthorizedStudents,
        AuthorizedParents: user.AuthorizedParents,
        AuthorizedClasses: user.AuthorizedClasses,
        Exams: user.Exams,
        DailyTextScores: user.DailyTextScores,
        SchoolInfo: user.SchoolInfo,
        StudentMessages: user.StudentMessages,
        AdminMessages: user.AdminMessages,
        Announcements: user.Announcements,
        Medals: user.Medals,
        Dictations: user.Dictations,
        ShopPresents_enviados: user.ShopPresents_enviados,
        ShopPresents_recibidos: user.ShopPresents_recibidos,
        ShopPresents_nuevos: user.ShopPresents_nuevos,
        SchoolName: user.SchoolName,
        Alias: user.Alias,
        EsAdmin: user.EsAdmin,
        BrothersCount: user.BrothersCount,
        _coins: user._coins,
        LastLogin: user.LastLogin,
        Coins: user.Coins,
        FullName: user.FullName,
        _validUntil: user._validUntil,
        ValidUntil: user.ValidUntil,
        IdHistoryStage: user.IdHistoryStage,
        MultTime: user.MultTime,
        SmallSession: user.SmallSession,
        SessionReports: user.SessionReports,
        WarningNoSession: user.WarningNoSession,
        nomail: user.nomail,
        HistoryStage: user.HistoryStage,
        LastRewardDate: user.LastRewardDate,
        BlockName: user.BlockName,
        BlockContact: user.BlockContact,
        BlockAlias: user.BlockAlias,
        RetosEnviados: user.RetosEnviados,
        RetosRecibidos: user.RetosRecibidos,
        GiftShopDisabled: user.GiftShopDisabled,
        BlockMessageGift: user.BlockMessageGift,
        AvatarDisabled: user.AvatarDisabled,
        Prepaid: user.Prepaid,
        SentTeacherMessages: user.SentTeacherMessages,
        ReceivedTeacherMessages: user.ReceivedTeacherMessages,
        AspNetUserLanguages: user.AspNetUserLanguages
    } 
  }