import { AuthService } from 'app/services/auth.service';
import { Component, OnInit, ViewEncapsulation, HostListener, OnDestroy } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { SharedService } from 'app/core/shared/shared.service';
import { UiService } from '@fuse/components/spinner/ui.service';
import { environment } from 'environments/environment';
import { AccountService } from 'app/main/authentication/account.service';
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators';


@Component({
    selector: 'redirection',
    templateUrl: './redirection.component.html',
    encapsulation: ViewEncapsulation.None,
    host: {
        '(window:resize)': 'onResize($event)'
    }
})
export class RedirectComponent implements OnInit, OnDestroy {
    private _unsubscribeAll: Subject<any>;

    mobile: boolean = false;
    errorMessage: string;
    code;

    constructor(
        private _router: Router,
        private route: ActivatedRoute,
        private _sharedService: SharedService,
        private _ui: UiService,
        private _authService: AuthService,

    ) {
        this._unsubscribeAll = new Subject();
        this._ui.spin$.next(true);
        this.code = this.route.snapshot.params.token;
    }

    ngOnDestroy() {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
        }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        event.target.innerWidth;
        this.mobile = this._sharedService.isMobile();
    }

    ngOnInit(): void {
        this.mobile = this._sharedService.isMobile();
        if (this._router.url.includes('redirection')) {
            this._authService.login(this.code, null,null,null);
        }
        else
            this._router.navigate(['./login']);

    }

}
