import { Injectable } from '@angular/core';
import { HttpClient, HttpSentEvent, HttpHeaderResponse, HttpResponse, HttpUserEvent, HttpProgressEvent, HttpHeaders } from '@angular/common/http';
import { IUserInfo } from './user-info';
import { Observable } from 'rxjs';
import * as moment from 'moment';
import { environment } from '../../../environments/environment'
import { AuthService } from 'app/core/guards/auth.service';
import { RepositoryService } from 'app/core/repository/repository.service';
import { map } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { FuseDialogContinueComponent } from '@fuse/components/dialog-continue/dialog-continue.component';
import { UserService } from 'app/services/user.service';
import { TranslateService } from '@ngx-translate/core';
@Injectable()
export class AccountService {

    private apiURL = environment.baseApi + "/api/User";

    constructor(
        private httpClient: HttpClient,
        private _authService: AuthService,
        private _repositoryService: RepositoryService,
        private _matDialog: MatDialog,
        private _userService: UserService,
        private _translateService: TranslateService
        
    ) {

    }

    create(userInfo: IUserInfo): Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {
        return this.httpClient.post<any>(this.apiURL + "/Create", userInfo);
    }

    login(userInfo): Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {
        let header = this._authService.contentHeaders();
        let requestBody = {
            username: userInfo.userName,
            password: encodeURIComponent(userInfo.password),
            version: environment.version,
            UserAgent: navigator.userAgent,
            UserMoment: moment().add(moment().utcOffset(), 'minutes'),
            UserMomentUTC: moment().utc(),
            FingerPrint: this._userService.getFingerPrint()
        };
        return this.httpClient.post<any>(environment.baseApi + "/connect/token", requestBody).pipe(map(r => { 
            if (r.badTimeRange) {
                let confirmDialog = this._matDialog.open(FuseDialogContinueComponent, {
                    disableClose: true
                });

                confirmDialog.componentInstance.title = "Hora del dispositivo no válida"
                confirmDialog.componentInstance.message1 = "Revisa la fecha y hora de tu dispositivo. La hora debe ser la hora actual del país en el que te encuentras.";
                confirmDialog.componentInstance.textButton = this._translateService.instant('ACCEPT');
                confirmDialog.componentInstance.margin = false;
                throw new Error();
            }
            return r;
        }));
    }

    getUserById(id) {
        return this._repositoryService.getAuth(this.apiURL + "/idUser/" + id)
    }

    confirmation(idUser: IUserInfo): Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {
        if (idUser !== null) {
            return this.httpClient.post<any>(this.apiURL + "/EmailConfirmSucceeded", idUser);
        }
    }

    forgot(userInfo: IUserInfo): Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {
        return this.httpClient.post<any>(this.apiURL + "/ForgotPassword", userInfo);
    }

    resetPassword(userInfo: IUserInfo): Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {
        return this.httpClient.post<any>(this.apiURL + "/ResetPassword", userInfo);
    }

    private generateHeaders() {
        let headers = new HttpHeaders();
        headers.append("Access-Control-Allow-Origin", "*");
        headers.append("Access-Control-Allow-Methods", "GET, POST, DELETE, PUT");
        headers.append("Access-Control-Allow-Headers", "X-Requested-With,content-type");
        headers.append("Access-Control-Allow-Credentials", "true");
        headers.append("Content-Type", "application/json");
        return {
            headers
        }
    }

    private generateHeadersAuth() {
        var clientId = 'Walinwa';
        var clientSecret = 'walSecrinwa';
        var credentials = clientId + ':' + clientSecret;
        var headerValue = btoa(credentials);
        let headers = new HttpHeaders();
        headers.append("Access-Control-Allow-Origin", "*");
        headers.append("Access-Control-Allow-Methods", "GET, POST, DELETE, PUT");
        headers.append("Access-Control-Allow-Headers", "X-Requested-With,content-type");
        headers.append("Access-Control-Allow-Credentials", "true");
        headers.append("Content-Type", "application/x-www-form-urlencoded");
        headers.append("Authorization", 'Basic ' + headerValue);
        return {
            headers
        }
    }
} 
