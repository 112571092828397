<div class="main_container" *ngIf="requestResetPasswordForm" [style.background-image]="'url(&quot;https://walinwa.blob.core.windows.net/images/esquina-inf-der.svg&quot;)'">
    <section fxLayout="column" fxLayoutAlign="stretch" class="box_options">
        <div>
            <div class="box_op">
                <div class="title">
                    <span>{{'AUTHENTICATION.REQ_RES_PW.TITLE' | translate}}</span>
                </div>
                <div class="cont">
                    <form class="walinwa-box-content" [formGroup]="requestResetPasswordForm" autocomplete="off"
                        novalidate>
                        <div style="margin-bottom: 1rem;">
                            <span [innerHTML]="'AUTHENTICATION.REQ_RES_PW.SUBTITLE' | translate"></span>
                        </div>
                        <div>
                            <label class="font_blue"><b>{{ 'USERNAME' | translate }}</b></label>
                            <mat-form-field class="spe-form-field">
                                <input matInput type="text" formControlName="userName">
                                <mat-error *ngIf="requestResetPasswordForm.get('userName').hasError('required')">
                                    {{'FIELD-REQUIRED' | translate}}
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div>
                            <label class="font_blue" *ngIf="!isStudent"><b>{{ 'EMAIL' |
                                    translate}}</b></label>
                            <label class="font_blue" *ngIf="isStudent"><b>{{ 'AUTHENTICATION.REQ_RES_PW.EMAIL-TEACHER' | translate
                                    }}</b></label>
                            <mat-form-field class="spe-form-field">
                                <input matInput type="email" formControlName="email">
                                <mat-error *ngIf="requestResetPasswordForm.get('email').invalid">
                                    {{ 'EMAIL-INVALID' | translate }}
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div *ngIf="errorMessage" class="error-message">
                            <mat-error>
                                {{errorMessage | translate}}
                            </mat-error>
                        </div>
                        <div fxLayoutAlign="end end" fxLayoutAlign.xs="center center" style="margin-top: 35px !important;">
                            <button mat-raised-button class="submit-button orange submit_no_top_margin fixed_button" aria-label="LOGIN"
                                (click)="submit(requestResetPasswordForm)">
                                {{ 'CONFIRM' | translate }}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </section>
</div>