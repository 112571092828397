import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PersonalizedTestStudentComponent } from './personalized-test.component';
import { FuseSharedModule } from '@fuse/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from 'app/shared.module';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
    declarations: [PersonalizedTestStudentComponent],
    imports: [
        CommonModule,
        TranslateModule.forChild(),
        SharedModule,
        FuseSharedModule,
        MatCheckboxModule,
        MatButtonModule
    ],
    exports: [
        PersonalizedTestStudentComponent
    ]
})
export class PersonalizedTestModule { }
