import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { BehaviorSubject } from 'rxjs';
import { CalendarHearingText } from 'app/core/shared/state/models/Hearing-text/hearing-text-month.model';
import { HearingText } from 'app/core/shared/state/models/Hearing-text/hearing-text.model';
import { HearingTextResult } from 'app/core/shared/state/models/Hearing-text/hearing-text-result.model';

@Injectable()
export class HearingTextService {

    baseUrl = environment.baseApi + '/api/HearingText';
    MonthlyHearing: CalendarHearingText[] = null;
    private s_reload_monthly_hearing = new BehaviorSubject<boolean>(false);
    reload_monthly_hearing = this.s_reload_monthly_hearing.asObservable();
    subDirectory = "/hearing/";
    constructor(private http: HttpClient) {
    }

    getHearingTextByMonth(idUser: number, month: number, year: number, level: number, idRole: number, creationDate: any) {
        return this.http.post<CalendarHearingText[]>(this.baseUrl + '/getHearingTextByMonth/', { idUser, month, year, level, idRole, creationDate });
    }

    getHearingTextByLevel(level: number, month: number, year: number) {
        return this.http.post<CalendarHearingText[]>(this.baseUrl + '/getHearingTextByLevel/', { level, month, year });
    }

    getHearingTextByid(IdHearingText: number, IdUser: number, selectedDate: number) {
        return this.http.post<HearingText>(this.baseUrl + '/getHearingTextByid/', { IdHearingText, IdUser, selectedDate });
    }

    answer(IdHearingText: number, IdQuestion: number, IdAnswer: number, IdUser: number, IsCorrect: boolean) {
        return this.http.post(this.baseUrl + '/answer/', { IdHearingText, IdQuestion, IdAnswer, IdUser, IsCorrect });
    }

    start(IdHearingText: number, IdUser: number, TextDate: any, NumExercises: number, IdQuestion: number, IdAnswer: number, IsCorrect: boolean) {
        return this.http.post(this.baseUrl + '/start/', { IdHearingText, IdUser, TextDate, NumExercises, IdQuestion, IdAnswer, IsCorrect });
    }
    restart(IdHearingText: number, IdUser: number, UserAnswersTotal: any, UserAnswersOk: number, Retries: number) {
        return this.http.post(this.baseUrl + '/restart/', { IdHearingText, IdUser, UserAnswersTotal, UserAnswersOk, Retries });
    }

    getScore(IdHearingText: number, IdUser: number, TextDate: any) {
        return this.http.post<HearingTextResult>(this.baseUrl + '/getScore/', { IdHearingText, IdUser, TextDate });
    }
    changeReloadMonthlyHearing(reload_monthly_hearing: boolean) {
        this.s_reload_monthly_hearing.next(reload_monthly_hearing);
    }

}
