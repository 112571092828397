import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FuseSharedModule } from '@fuse/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { WaliLetrasComponent } from './wali-letras.component';

@NgModule({
    declarations: [WaliLetrasComponent],
    imports: [
        CommonModule,
        FuseSharedModule,
        TranslateModule.forChild(),
    ],
    exports: [
        WaliLetrasComponent
    ]
})
export class WaliLetrasModule { }
