import { Injectable } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { RepositoryService } from 'app/core/repository/repository.service';
import { MatDialog } from '@angular/material/dialog';
import { FuseDialogContinueComponent } from '@fuse/components/dialog-continue/dialog-continue.component';

declare var $;

@Injectable()
export class GlobalService {

    inExercise = false;
    forcingOrientation;
    isSessionStorageSupported = true;
    currentUser;
    //versionError = false;
    FormLogAcivated = true;

    constructor(
        private _deviceService: DeviceDetectorService,
        private _repositoryService: RepositoryService,
        private _matDialog: MatDialog
        ) {}

    forceOrientation(orientation) {
        let deviceInfo = this._deviceService.getDeviceInfo();
        let browser = deviceInfo.browser.toUpperCase();
        let isMobile = this._deviceService.isMobile();
        let isTablet = this._deviceService.isTablet();
        let isDesktop = this._deviceService.isDesktop();
        let screenOrientation = screen && screen.orientation;
        // @ts-ignore
        let canLockOrientation = screen && screen.orientation ? typeof screen.orientation.lock === "function" : false;
        if (!canLockOrientation) return;
        if (isDesktop) return;
        // @ts-ignore
        screen.orientation.lock(orientation)

    }

    checkIfIsSessionStorageSupported() {
        let isSupported = false;
        try {
            sessionStorage.setItem('test', 'test');
            if (sessionStorage.getItem('test') == 'test') isSupported = true;
            sessionStorage.removeItem('test');
        } catch (e) {
            this.clientError('El sessionStorage no está permitido: ' + e.message);
            isSupported = false;
        }
        return isSupported;
    }

    clientError(msg, idUser = null) {
        try {
            if (sessionStorage.getItem("currentUser")) {
                let currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
                if (currentUser.Id) idUser = currentUser.Id;
            }
        } catch (e) { }
        let url = 'api/ClientError';
        let data = {
            IdUser: idUser,
            Error: 'Browser:' + navigator.userAgent + ' Exception: ' + msg
        };
        this._repositoryService.put(url, data).subscribe((response: any) => {
            
        },
        error => {});
    }

    showMessage(obj={}) {
        let confirmDialog = this._matDialog.open(FuseDialogContinueComponent, {
            disableClose: true
        });
        for(let key in obj) confirmDialog.componentInstance[key] = obj[key];
        confirmDialog.componentInstance.margin = false;
        confirmDialog.afterClosed().subscribe(result => {
            if(!result) return;
            result();
        });
    }
}
