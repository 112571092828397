import { SchoolService } from 'app/services/school.service';
import { Component, OnInit, ViewEncapsulation, HostListener, Renderer2, AfterViewInit, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

// import { SharedService } from './service/shared.service';


import { School, DataService, DataQuery } from 'app/core/shared/state';
import { UiService } from '@fuse/components/spinner/ui.service';
import { SharedService } from 'app/core/shared/shared.service';
import { AppService } from 'app/app.service';
import { ToolbarService } from 'app/services/toolbarService.service';
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators';


@Component({
    selector: "free-trial-educational",
    templateUrl: "./free-trial-educational.component.html",
    styleUrls: ["./free-trial-educational.component.scss"],
    encapsulation: ViewEncapsulation.None,
    host: {
        "(window:resize)": "onResize($event)",
    },
})
export class FreeTrialEducationalComponent implements OnInit, AfterViewInit, OnDestroy {
    private _unsubscribeAll: Subject<any>;

    school: School;
    message: string;
    name: string;
    initials: string;
    street: string;
    state: string;
    phone: string;
    country: string;
    // countries = ['España', 'Portugal', 'Inglaterra'];
    origin: string;
    freeTrialEducationalForm: UntypedFormGroup;
    mobile: boolean = false;
    regex = /[a-zA-Z0-9ñÑáéíóúÁÉÍÓÚ\s\.\-]+$/;
    iPad = /iPad|Macintosh/i.test(navigator.userAgent);
    mobileDetect;

    constructor(
        private _formBuilder: UntypedFormBuilder,
        private _router: Router,
        public _sharedService: SharedService,
        private _dataService: DataService,
        private _ui: UiService,
        private schoolService: SchoolService,
        private appService: AppService,
        private toolbarService: ToolbarService,
        private renderer: Renderer2,
        private dataQuery: DataQuery,
    ) {
        this._unsubscribeAll = new Subject();
        this._ui.spin$.next(true);
        this.mobileDetect = this.appService.mobileDetect();
    }

    ngOnDestroy() {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    ngAfterViewInit(){

        const matToolbarRef = this.toolbarService.getToolbarRef();
        if (matToolbarRef) {
            setTimeout(() => {
                this.renderer.addClass(
                    matToolbarRef.nativeElement,
                    "only-logo"
                );
            }, 0);
        }
    }

    @HostListener("window:resize", ["$event"])
    onResize(event) {
        event.target.innerWidth;
        this.mobile = this._sharedService.isMobile();
    }

    ngOnInit(): void {
        this.mobile = this._sharedService.isMobile();
        this.initForm();
        
    }

    initForm(): void {
        this.freeTrialEducationalForm = this._formBuilder.group({
            name: [this.dataQuery.school ? this.dataQuery.school.name : "", [Validators.required, Validators.pattern(this.regex)]],
            state: [this.dataQuery.school ? this.dataQuery.school.state : "", Validators.required],
            country: [this.dataQuery.school ? this.dataQuery.school.country : "ES", Validators.required],
            origin: [this.dataQuery.school ? this.dataQuery.school.origin : "", []],
        });
        //this.freeTrialEducationalForm.markAllAsTouched();
    }

    changeCountry(value) {
        this.freeTrialEducationalForm.value.country = value;
    }

    continue() {
        this.freeTrialEducationalForm.markAllAsTouched();

        if (this.freeTrialEducationalForm.valid) {
            this.school = Object.assign(
                {},
                this.freeTrialEducationalForm.value
            );
            this._ui.showSpinner();
            this.schoolService
                .setInitials(this.freeTrialEducationalForm.value.name.trim())
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe(
                    (result: any) => {
                        this._ui.stopSpinner();
                        this.school.initials = result.initials;
                        this.school.name = this.school.name.trim();
                        this._dataService.saveDataSchool(this.school);
                        this._router.navigate(['/free-trial/admin-info']);
                    },
                    (error) => {
                        if (error.error == "name") {
                            this.freeTrialEducationalForm.get('name').setErrors({ nameNotValid: true });
                        }
                        this._ui.stopSpinner();
                    }
                );
        } else {
            document
                .getElementById("extra_margin_mobile")
                .classList.add("margin_topper_info");
        }
    }
}
