import {
    Component,
    OnInit,
    HostListener,
    ViewChild,
    ElementRef,
    AfterViewInit,
    ViewEncapsulation,
    ChangeDetectorRef,
} from "@angular/core";
import { Location } from "@angular/common";
import { FuseTranslationLoaderService } from "@fuse/services/translation-loader.service";
import {
    Router,
    ActivatedRoute,
    RoutesRecognized,
} from "@angular/router";
import { GlobalService } from "app/services/global.service";
import { SharedService } from "../../../core/shared/shared.service";
import { AuthService } from "app/services/auth.service";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { AppService } from "app/app.service";
import { environment } from "environments/environment";
import { ToolbarService } from "app/services/toolbarService.service";

@Component({
    selector: "footer",
    templateUrl: "./footer.component.html",
    styleUrls: ["./footer.component.scss"],
    encapsulation: ViewEncapsulation.None,
    host: {
        "(window:resize)": "onResize($event)",
    },
})
export class FooterComponent implements OnInit, AfterViewInit {
    showGoBack: boolean;
    routeIndex: boolean;
    IsDesktop: boolean = false;
    mobile: boolean = false;
    disableButton = false;
    iPad = /iPad|Macintosh/i.test(navigator.userAgent);
    mobileDetect;
    version: any;
    versionIsShown: boolean;
    @ViewChild("matToolbar", { static: false, read: ElementRef })
    matToolbar: ElementRef;
    showAppInfoButons = false;

    constructor(
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _router: Router,
        private _location: Location,
        private _sharedService: SharedService,
        public globalService: GlobalService,
        private authService: AuthService,
        private iconRegistry: MatIconRegistry,
        private sanitizer: DomSanitizer,
        private appService: AppService,
        private toolbarService: ToolbarService,
        private activatedRoute: ActivatedRoute,
        private _detector: ChangeDetectorRef
    ) {
        iconRegistry.addSvgIcon(
            "change_history",
            sanitizer.bypassSecurityTrustResourceUrl("https://walinwa.blob.core.windows.net/images/icono-boton-volver.svg?ngsw-bypass=true")
        );
        this.showGoBack = false;
        this.routeIndex = true;
        let isDesktop =
            !/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                navigator.userAgent
            );
        this.IsDesktop = isDesktop;
        this.mobileDetect = this.appService.mobileDetect();
        this.version = environment.version.substring(3);

        if (environment.production) this.versionIsShown = false;
        else this.versionIsShown = true;
    }

    getCurrentUrl(): string {
        return this._router.url;
    }

    showToolBar() {
        let bReturn: boolean =
            !this.globalService.inExercise &&
            !this.schoolAdmin() &&
            !this.maintenance();
        let currentUrl = this.getCurrentUrl();
        if (currentUrl.toLocaleLowerCase() == "/login" || currentUrl == "/") {
            this.toolbarService.setToolbarRef(this.matToolbar);
        }
        return bReturn;
    }

    ngAfterViewInit() {
        this.toolbarService.setToolbarRef(this.matToolbar);
    }

    @HostListener("window:resize", ["$event"])
    onResize(event) {
        // window.screen.width <= 768 ? true : false;
        this.mobile = this._sharedService.isMobile();
    }

    @HostListener("focus", ["$event"])
    onfocus(event) {
        // window.screen.width <= 768 ? true : false;
        console.log(event);
    }

    ngOnInit() {
        this.mobile = this._sharedService.isMobile();

        this._router.events.subscribe((val: RoutesRecognized) => {
            if (val.url != undefined) {
                //this.disableButton = val.url == "/admin-guide" ? true : false
                this.routeIndex =
                    val.url == "/login" || val.url == "/" ? true : false;
                this.showAppInfoButons =
                    val.url.includes("free-trial") ? true : false;

                this._detector.detectChanges();
            }
            
        });
    }

    imLogged() {
        return sessionStorage.getItem("currentUser") != null;
    }

    goToLogin() {
        this._router.navigate(["./login"], {relativeTo: this.activatedRoute});
    }

    pruebaGratis() {
        this._router.navigate(["./free-trial"], {relativeTo: this.activatedRoute});
    }

    renovarLicencia() {
        this._router.navigate(["./renew-licence"], {relativeTo: this.activatedRoute});
    }

    altaInicial() {
        this._router.navigate(["./initial-registration"], {relativeTo: this.activatedRoute});
    }
    openPDF() {
    var link = document.createElement("a");
    link.setAttribute("href", "https://www.walinwa.com/Walinwa_folleto.pdf");
    link.setAttribute("target", "_blank");
    link.setAttribute("download", "");
    document.body.appendChild(link);
    link.click();
    }

    openVideo() {
        var link = document.createElement("a");
        link.setAttribute("target", "_blank");
        link.href = "https://www.walinwa.com/Walinwa.mp4";
        link.setAttribute("target", "_blank");
        document.body.appendChild(link);
        link.click();
    }

    goBack() {
        this._location.back();
    }

    schoolAdmin() {
        let href = location.href.split("/");
        return (
            href[href.length - 1] == "admin" ||
            location.href.includes("demand") ||
            location.href.includes("school") ||
            (location.href.includes("student") &&
                !location.href.includes("student-info") &&
                !location.href.includes("new-student")) ||
            location.href.includes("redirection")
        );
    }

    maintenance() {
        return location.href.includes("maintenance");
    }

    currentUser() {
        try {
            if (sessionStorage.getItem("currentUser")) {
                return JSON.parse(sessionStorage.getItem("currentUser"));
            }
        } catch (e) {
            return null;
        }
        return null;
    }
}
