import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';

@Injectable()

export class AnnouncementService {

  baseUrl = environment.baseApi + '/api/Announcement';
  subDirectory = "Announcements"

  constructor(private http: HttpClient) {
    
  }
  getAnnouncement(idUser) {
    return this.http.get(this.baseUrl + '/idUser/' + idUser);
  };

  setAnnouncementUser(ReleaseDate, idUser) {
    return this.http.post(this.baseUrl + '/idAnnouncement/' + ReleaseDate + '/idUser/' + idUser, {});
  };


}
