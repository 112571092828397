import { HearingTextService } from './services/hearing-text.service';
import { LogService } from 'app/services/log.service';
import { RetoService } from './services/reto.service';
import { ClassService } from 'app/services/class.service';
import { ApplicationRef, ErrorHandler, Injector, NgModule, isDevMode } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { CommonModule } from "@angular/common";
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";
import { MatMomentDateModule } from "@angular/material-moment-adapter";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import {
    MatProgressSpinnerModule,
} from "@angular/material/progress-spinner";
import { TranslateLoader, TranslateModule, TranslateService } from "@ngx-translate/core";
import { PathLocationStrategy, LocationStrategy } from "@angular/common";
import 'hammerjs';
import { FuseModule } from "@fuse/fuse.module";
import { FuseSharedModule } from "@fuse/shared.module";
import {
    FuseProgressBarModule,
    FuseSidebarModule,
    FuseThemeOptionsModule,
} from "@fuse/components";
import { SpinnerModule } from "@fuse/components/spinner/spinner.module";

import { fuseConfig } from "app/fuse-config";
import { ErrorInterceptorService } from "app/core/guards/error-interceptor.service";
import { AuthInterceptorService } from "app/core/guards/auth-interceptor.service";
import { AuthGuardService, canActivateRoute } from "app/core/guards/auth-guard.service";

import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatFormFieldModule } from "@angular/material/form-field";

import { MatInputModule } from "@angular/material/input";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatTabsModule } from '@angular/material/tabs';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';

import { AppComponent } from "app/app.component";
import { LayoutModule } from "app/layout/layout.module";
import { CoreModule } from "app/core/core.module";
import { AppService } from "./app.service";
import { LoginModule } from "./main/authentication/login/login.module";
import { InitialRegistrationModule } from "./main/authentication/initial-registration/initial-registration.module";
import { RenewLicenseComponent } from "./main/authentication/renew-license/renew-license.component";
import { FreeTrialModule } from "./main/authentication/free-trial/free-trial.module";
import { ResetPasswordModule } from "./main/authentication/reset-password/reset-password.module";
import { RequestResetPasswordModule } from "./main/authentication/request-reset-password/request-reset-password.module";

import { DragDropModule } from '@angular/cdk/drag-drop';

import { GlobalService } from "./services/global.service";
import { VersionService } from "./services/version.service";
import { TestService } from "./services/test.service";
import { ExerciseService } from "./services/exercise.service";
import { DictationService } from "./services/dictation.service";
import { SharedService } from "app/core/shared/shared.service";
import { AccountService } from "app/main/authentication/account.service";
import { SchoolService } from "./services/school.service";
import { UserService } from "./services/user.service";
import { CourseService } from "./services/course.service";
import { PaymentService } from "./services/payment.service";
import { SessionService } from "./services/session.service";
import { CustomizedSessionService } from "./services/customized-session.service";
import { EmailConfigService } from "./services/email-config.service";
import { ReportService } from "./services/report.service";
import { NavigationService } from "./services/navigation.service";
import { AuthService } from "./services/auth.service";
import { ReadingTextService } from "./services/reading-text.service";
import { ShopService } from "./services/shop.service";
import { AdminMessageService } from "./services/adminMessages.service";
import { DailyTextService } from "./services/daily-text.service";
import { CoinService } from "./services/coin.service";
import { CivilizationService } from "./services/civilization.service";
import { AnnouncementService } from "./services/announcement.service";
import { OnlineStatusModule } from "ngx-online-status";
import { WaliLetrasService } from "./services/wali-letras.service";
import { ImageService } from "./services/image.service";
import { FooterAuthModule } from "./main/authentication/footer-auth/footer-auth.module";
import { RedirectModule } from "./main/authentication/redirection/redirection.module";
import { FileService} from "./services/file.service"
import { SharedModule } from './shared.module';
import { ProfileConditions, Profiles } from './core/shared/session.model';
import { CustomErrorHandler } from './core/logging/error-handler';
import { ServiceWorkerModule, SwUpdate } from '@angular/service-worker';
import { APP_INITIALIZER } from '@angular/core';
import { WalinwaUpdateService, init } from './services/walinwa-update.service';
import { environment } from 'environments/environment';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import { MatTreeModule } from '@angular/material/tree';
import { SortByPipe } from './pipes/sort-by.pipe';
import { WaliLeeService } from './services/wali-lee.service';
import { DxDataGridModule } from 'devextreme-angular/ui/data-grid';
import { DxBulletModule, DxButtonModule, DxListModule, DxSelectBoxModule, DxTemplateModule } from 'devextreme-angular';
import { ComponentsModule } from './components/components.module';

export const appRoutes: Routes = [
    {
        path: "",
        loadChildren: () =>
            import("./main/main.module").then((m) => m.MainModule),
    },
    { path: "maintenance", 
        loadChildren: () =>
        import("./main/maintenance/maintenance.module").then((m) => m.MaintenanceModule), 
    },
    {
        path: "login",
        loadChildren: () =>
            import("./main/authentication/login/login.module").then(
                (m) => m.LoginModule
            ),
    },
    {
        path: "free-trial",
        loadChildren: () =>
            import("./main/authentication/free-trial/free-trial.module").then(
                (m) => m.FreeTrialModule
            ),
    },
    {
        path: "renew-licence",
        component: RenewLicenseComponent
    },
    {
        path: "initial-registration",
        loadChildren: () =>
            import(
                "./main/authentication/initial-registration/initial-registration.module"
            ).then((m) => m.InitialRegistrationModule),
    },
    {
        path: "redirection/:token",
        loadChildren: () =>
            import("./main/authentication/redirection/redirection.module").then(
                (m) => m.RedirectModule
            ),
    },
    {
        path: "request-reset-password",
        loadChildren: () =>
            import(
                "./main/authentication/request-reset-password/request-reset-password.module"
            ).then((m) => m.RequestResetPasswordModule),
    },
    {
        path: "resetpassword/:idUser/:token",
        loadChildren: () =>
            import(
                "./main/authentication/reset-password/reset-password.module"
            ).then((m) => m.ResetPasswordModule),
    },
    {
        path: "resetpasswordintern/:idUser/:token",
        loadChildren: () =>
            import(
                "./main/authentication/reset-password/reset-password.module"
            ).then((m) => m.ResetPasswordModule),
    },
    {
        path: "admin",
        canActivate: [canActivateRoute],
        data: { AllowedProfiles: [Profiles.IsSuperAdmin] },
        loadChildren: () =>
            import("./main/admin/admin.module").then((m) => m.AdminModule),
    },
    {
        path: "school",
        loadChildren: () => import("./main/school/school.module").then((m) => m.SchoolModule),
        runGuardsAndResolvers: "always",
        canActivate: [canActivateRoute],
        data: { AllowedProfiles: [Profiles.IsSchoolAdmin, Profiles.IsTutor, Profiles.IsSuperAdmin] },
    },
    {
        path: "demand",
        canActivate: [canActivateRoute],
        data: { AllowedProfiles: [Profiles.OnDemand] },
        loadChildren: () => import("./main/demand/demand.module").then((m) => m.DemandModule)
    },
    {
        path: "student/session/play",
        canActivate: [canActivateRoute],
        data: { AllowedProfiles: [Profiles.IsStudent, Profiles.IsTeacher, Profiles.IsSuperAdmin, Profiles.OnDemand, Profiles.IsSchoolAdmin, Profiles.IsParent] },
        loadChildren: () => import("./main/exercise/exercise.module").then((m) => m.ExerciseModule)
    },    
    {
        path: "student",
        loadChildren: () => import("./main/student/student.module").then((m) => m.StudentModule),
        canActivate: [canActivateRoute],
        data: { AllowedProfiles: [Profiles.IsStudent, { Profile: Profiles.IsTeacher, Assert: ProfileConditions.IsDemoUser }, Profiles.IsSuperAdmin, Profiles.OnDemand, { Profile: Profiles.IsSchoolAdmin, Assert: ProfileConditions.IsDemoUser }, Profiles.IsParent] },
    },
];

@NgModule({
    declarations: [
        AppComponent,
        RenewLicenseComponent
    ],
    imports: [
        SharedModule,
        MatTabsModule,
        ComponentsModule,
        CommonModule,
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule.forRoot(appRoutes, { onSameUrlNavigation: "reload", preloadingStrategy: PreloadAllModules }),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (HttpLoaderFactory),
                deps: [HttpClient]
            },
        }),
        MatMomentDateModule,
        MatButtonModule,
        MatIconModule,
        MatProgressSpinnerModule,
        MatCheckboxModule,
        MatFormFieldModule,
        MatInputModule,
        MatRadioModule,
        MatSelectModule,
        MatDatepickerModule,
        MatAutocompleteModule,
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,
        SpinnerModule,
        CoreModule,
        LayoutModule,
        LoginModule,
        InitialRegistrationModule,
        FreeTrialModule,
        RequestResetPasswordModule,
        ResetPasswordModule,
        OnlineStatusModule,
        DragDropModule,
        MatDialogModule,
        FooterAuthModule,
        RedirectModule,
        MatTreeModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
          enabled: !isDevMode(),
          registrationStrategy: 'registerImmediately'
        }),
        DxDataGridModule,
        DxBulletModule,
        DxTemplateModule,
        DxSelectBoxModule,
        DxButtonModule,
        DxListModule/*,
        QuestionaireModule*/
    ],
    providers: [
        AuthGuardService,
        AppService,
        { provide: MatMomentDateModule, useValue: "es-ES" },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptorService,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorInterceptorService,
            multi: true,
        },
        { provide: LocationStrategy, useClass: PathLocationStrategy },
        {
            provide: MatDialogRef,
            useValue: {}
        },        
        GlobalService,
        CoinService,
        VersionService,
        TestService,
        SharedService,
        AccountService,
        SchoolService,
        UserService,
        ClassService,
        RetoService,
        CourseService,
        PaymentService,
        CustomizedSessionService,
        SessionService,
        ExerciseService,
        DictationService,
        EmailConfigService,
        ReportService,
        NavigationService,
        AuthService,
        ReadingTextService,
        HearingTextService,
        ShopService,
        AdminMessageService,
        DailyTextService,
        CivilizationService,
        AnnouncementService,
        WaliLetrasService,
        WaliLeeService,
        ImageService,
        FileService,
        SortByPipe,
        LogService,
        {
            provide: ErrorHandler,
            useClass: CustomErrorHandler
        },
        { provide: APP_INITIALIZER, useFactory: init, deps: [ApplicationRef, SwUpdate, WalinwaUpdateService, TranslateService, Injector, UserService], multi: true }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
export function HttpLoaderFactory(httpClient: HttpClient) {
    return new MultiTranslateHttpLoader(httpClient, [
        { prefix: `${environment.filesUrl}/i18n/`, suffix: `.json?date=${new Date().getTime()}` }
        //{ prefix: './assets/i18n/', suffix: '.json' }
    ])
}