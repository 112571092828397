<div class="main_container" [style.background-image]="'url(&quot;https://walinwa.blob.core.windows.net/images/esquina-inf-der.svg&quot;)'">
    <section fxLayout="column" fxLayoutAlign="stretch" class="box_options">
        <div>
            <div class="box_op">
                <div class="title">
                    <span *ngIf="step == 1">{{ 'AUTHENTICATION.FREE-TRIAL.FREE-TRIAL-PARTICULAR.FAMILY-DATA' | translate }}</span>
                    <span *ngIf="step == 2">{{ 'AUTHENTICATION.FREE-TRIAL.VERIFY-MAIL-CODE' | translate }}</span>
                    
                </div>
                <div class="cont" [hidden]="step == 2">
                    <form *ngIf="freeTrialParticularForm" class="walinwa-box-content" [formGroup]="freeTrialParticularForm" autocomplete="new-password">
                        <div fxLayout="column" fxLayout.gt-sm="row" fxNgClass.gt-sm="spe_gap">
                            <div fxFlex="">
                                <label class="font_blue"><b>{{ 'NAME' | translate }}</b></label>
                                <mat-form-field class="spe-form-field">
                                    <input matInput type="text" class="form-control " formControlName="name">
                                    <mat-error *ngIf="freeTrialParticularForm.get('name').hasError('required')">
                                        {{ 'FIELD-REQUIRED' | translate }}
                                    </mat-error>
                                    <mat-error *ngIf="freeTrialParticularForm.get('name').hasError('pattern')"
                                    class="customized_error two">
                                    {{ 'PATTERN-ERROR' | translate }}
                                </mat-error>
                                </mat-form-field>
                            </div>
                            <div fxFlex="">
                                <label class="font_blue"><b>{{ 'FAMILYNAME' | translate }}</b></label>
                                <mat-form-field class="spe-form-field">
                                    <input matInput type="text" class="form-control " formControlName="familyName">
                                    <mat-error *ngIf="freeTrialParticularForm.get('familyName').hasError('pattern')"
                                    class="customized_error two">
                                    {{ 'PATTERN-ERROR' | translate }}
                                </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <div>
                            <label class="font_blue"><b>{{ 'EMAIL' | translate }}</b></label>
                            <mat-form-field class="spe-form-field">
                                <input matInput type="email" class="form-control " formControlName="email">
                                <mat-error *ngIf="freeTrialParticularForm.get('email').invalid">
                                    {{ 'EMAIL-INVALID' | translate }}
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div *ngIf="hasError()" class="error-message">
                            <mat-error>
                                {{ errorMessage | translate}}
                            </mat-error>
                        </div>
                        <div>
                            <mat-checkbox formControlName="privacy">
                                <div [innerHTML]=" 'AUTHENTICATION.FREE-TRIAL.SERVICE-AND-POLICY' | translate: {url: url}">
                                </div>
                                <mat-error *ngIf="!freeTrialParticularForm.value.privacy && submited">
                                    {{'ACCEPT-CONDITIONS-ERROR' | translate}}
                                    </mat-error>
                            </mat-checkbox>
                        </div>
                        <div fxLayoutAlign="end end" fxLayoutAlign.xs="center center" style="margin-top: 35px !important;">
                            <button mat-raised-button (click)="proceed($event,1)" class="submit-button orange fixed_button">
                                {{ 'CONTINUE' | translate }}
                            </button>
                        </div>
                    </form>
                </div>
                <div class="cont" *ngIf="step == 2">
                    <div fxLayout="column" fxLayoutAlign="stretch" class="spe_gap">
                        <div>
                            <label class="font_blue"><b>{{'AUTHENTICATION.FREE-TRIAL.CODE-INFO' | translate}}</b></label>
                            <mat-form-field class="spe-form-field two ff-usercode">
                                <input matInput type="text" class="form-control " name="userCode"
                                    [(ngModel)]="userCode">
                            </mat-form-field>
                        </div>
                        <div *ngIf="codeError">
                            <mat-error>
                                {{ 'AUTHENTICATION.ERROR.CODE' | translate }}
                            </mat-error>
                        </div>
                        <div class="spe_gap" fxLayout="row wrap" fxLayoutAlign="space-between center" fxLayoutAlign.xs="center center" style="margin-top: 35px !important;">
                            <button [disabled]="timer > 0" mat-raised-button color="accent" (click)="proceed($event,1)"
                                class="submit-button fixed_button">
                                {{ timer > 0 ? timer : 'AUTHENTICATION.FREE-TRIAL.RESEND' | translate }}
                            </button>
                            <button mat-raised-button color="accent" (click)="proceed($event,2)" class="submit-button orange fixed_button">
                                {{ 'CONTINUE' | translate }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>