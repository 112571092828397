<div class="main_container" [style.background-image]="'url(&quot;https://walinwa.blob.core.windows.net/images/esquina-inf-der.svg&quot;)'">
    <section fxLayout="column" fxLayoutAlign="stretch" class="box_options">
        <div class="box_op">
            <div class="title">
                <span>{{ 'AUTHENTICATION.RES_PW.TITLE' | translate }}</span>
            </div>
            <div class="cont">
                <form class="" fxLayout="column" fxLayoutAlign="stretch" [formGroup]="resetPasswordForm"
                    autocomplete="off">
                    <div style="margin-bottom: 1rem;">
                        {{'AUTHENTICATION.RES_PW.SUBTITLE' | translate}}
                    </div>
                    <div style="margin-bottom: .25rem;">
                        <label class="font_blue">{{ 'AUTHENTICATION.RES_PW.PASSWORD1' | translate }}</label>
                        <mat-form-field class="spe-form-field" floatLabel="always">
                            <input matInput [type]="showPassword1 ? 'text' : 'password'" formControlName="password1">
                            <mat-icon class="show-password-icon" [svgIcon]="showPassword1 ? 'gafas_off' : 'gafas_on'"
                                matSuffix (click)="showPassword1 = !showPassword1">
                            </mat-icon>
                            <mat-error *ngIf="isSubmitted && resetPasswordForm.get('password1').hasError('required')">
                                {{'FIELD-REQUIRED' | translate}}
                            </mat-error>
                            <mat-error *ngIf="resetPasswordForm.get('password1').hasError('minlength')">
                                {{'PASSWORD-LENGTH' | translate}}
                            </mat-error>
                            <mat-error *ngIf="resetPasswordForm.get('password1').hasError('maxlength')">
                                {{'PASSWORD-LENGTH' | translate}}
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div>
                        <label class="font_blue">{{ 'AUTHENTICATION.RES_PW.PASSWORD2' | translate }}</label>
                        <mat-form-field class="spe-form-field" floatLabel="always">
                            <input matInput [type]="showPassword2 ? 'text' : 'password'" formControlName="password2">
                            <mat-icon class="show-password-icon" [svgIcon]="showPassword2 ? 'gafas_off' : 'gafas_on'"
                                matSuffix (click)="showPassword2 = !showPassword2">
                            </mat-icon>
                            <mat-error *ngIf="isSubmitted && resetPasswordForm.get('password2').hasError('required')">
                                {{'FIELD-REQUIRED' | translate}}
                            </mat-error>
                            <mat-error *ngIf="resetPasswordForm.get('password2').hasError('minlength')">
                                {{'PASSWORD-LENGTH' | translate}}
                            </mat-error>
                            <mat-error *ngIf="resetPasswordForm.get('password2').hasError('maxlength')">
                                {{'PASSWORD-LENGTH' | translate}}
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div *ngIf="resetPasswordForm.errors?.mismatch && isSubmitted">
                        <mat-error>
                            {{'AUTHENTICATION.RES_PW.PASSWORD-DIFFERENT' | translate}}
                        </mat-error>
                    </div>
                    <div fxLayout="row wrap" fxLayoutAlign="end end" [ngStyle]="{'margin-top': resetPasswordForm.errors?.mismatch && isSubmitted ? '0' : '24px'}">
                        <button mat-raised-button class="submit-button orange fixed_button submit_no_top_margin" aria-label="LOGIN"
                            (click)="submit(resetPasswordForm)">
                            {{ 'CONFIRM' | translate }}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </section>
</div>