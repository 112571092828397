import { NgModule } from "@angular/core";
import { SortByPipe } from "./pipes/sort-by.pipe";
import { ReplaceTags } from "./pipes/replace-tags.pipe";
import { DigitSpacingPipe } from "./pipes/digit-spacing.pipe";
import { ReplaceDot } from "./pipes/replace-dot.pipe";
import { ToLocalTime } from "./pipes/to-local-time.pipe";
import { ToLocalHours } from "./pipes/to-local-hours.pipe";
import { ToUtcTimePipe } from "./pipes/to-utc-hours.pipe";

@NgModule({
    imports: [],
    declarations: [
        SortByPipe,
        ReplaceTags,
        ToLocalTime,
        ReplaceDot,
        DigitSpacingPipe,
        ToLocalHours,
        ToUtcTimePipe
    ],
    exports: [
        SortByPipe,
        ReplaceTags,
        ToLocalTime,
        ReplaceDot,
        DigitSpacingPipe,
        ToLocalHours,
        ToUtcTimePipe
    ],
    providers: [
        ToUtcTimePipe
    ]
})
export class SharedModule
{
}