import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { ShopItem, ShopItemUser } from 'app/core/shared/state';

@Injectable()
export class ShopService {

    baseUrl = environment.baseApi + '/api/ShopItem';
    BoughtItems: ShopItemUser[] = []
    private s_user_bought_items = new Subject<ShopItemUser[]>();
    user_bought_items = this.s_user_bought_items.asObservable();
    constructor(private http: HttpClient) {
    }
    ChangeUserBoughtItems(user_bought_items: ShopItemUser[]) {
        this.BoughtItems = user_bought_items;
        this.s_user_bought_items.next(user_bought_items);
    }
    getAvailableItems(idUser) {
        return this.http.get(this.baseUrl + "/available/" + idUser);
    }

    getAvailableItemsCiv(idUser, idCiv) {
        return this.http.get(this.baseUrl + "/available/" + idUser + "/idCivilization/" + idCiv);
    }

    getBoughtByIdUser(idUser: number) {
        return this.http.get<ShopItemUser[]>(this.baseUrl + "/bought/" + idUser);
    }

    getBoughtGamesByIdUser(idHistoryStage: number) {
        return this.http.get<ShopItem[]>(this.baseUrl + "/boughtGames/" + idHistoryStage);
    }

    setShopItemToUserShop(idUser, idShopItem) {
        return this.http.get(this.baseUrl + "/idUser/" + idUser + "/idShopItem/" + idShopItem);
    }

    sell(idShopItem, idUser) {
        return this.http.get(this.baseUrl + "/Sell/idShopItem/" + idShopItem + "/idUser/" + idUser);
    }

    checkRegalar(idShopItem, idUserFrom, idUserto) {
        return this.http.post(this.baseUrl + "/CheckRegalar/idShopItem/" + idShopItem + "/idUserFrom/" + idUserFrom + "/idUserto/" + idUserto, {});
    }

    regalar(IdShopItem: number, IdUserFrom: number, IdUserTo: number, Message: string) {
        let params = { Message: Message, IdShopItem: IdShopItem, IdUserFrom: IdUserFrom, IdUserTo: IdUserTo };
        return this.http.post(this.baseUrl + "/Regalar", params);
    }

    getAllTransactions(idUser) {
        return this.http.get(this.baseUrl + "/idUser/" + idUser + "/transactions");
    }

    activateMap(idUser, idShopItem) {
        return this.http.get(this.baseUrl + "/idUser/" + idUser + "/activateMap/" + idShopItem);
    }

    activateComponent(idUser, idShopItem) {
        return this.http.get(this.baseUrl + "/idUser/" + idUser + "/activateComp/" + idShopItem);
    }

    deactivateComponent(idUser, idShopItem) {
        return this.http.get(this.baseUrl + "/idUser/" + idUser + "/deactivateComp/" + idShopItem);
    }

    activateMessage(idUser, idShopItem) {

        return this.http.get(this.baseUrl + "/idUser/" + idUser + "/activateMsg/" + idShopItem);
    }

    deactivateMessage(idUser, idShopItem) {
        return this.http.get(this.baseUrl + "/idUser/" + idUser + "/deactivateMsg/" + idShopItem);
    }

    activateOther(idUser, idShopItem) {
        return this.http.get(this.baseUrl + "/idUser/" + idUser + "/activateOther/" + idShopItem);
    }

    deactivateOther(idUser, idShopItem) {
        return this.http.get(this.baseUrl + "/idUser/" + idUser + "/deactivateOther/" + idShopItem);
    }

}
