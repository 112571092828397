import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';

@Injectable()
export class VersionService {

    baseUrl = environment.baseApi + '/api/Version';

    constructor(private http: HttpClient) {
    }

    getVersion() {
        return this.http.get(this.baseUrl);
    }

    ping() {
        return this.http.get(this.baseUrl + "/ping");
    }

}
