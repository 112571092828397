import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';

@Injectable()
export class CivilizationService {

    baseUrl = environment.baseApi + '/api/Civilization';
    civilizations = [];
    constructor(private http: HttpClient) {
    }

    getCivilizations() {
        return this.http.get(this.baseUrl);
    }

    getCivilizationsUser(idUser) {
        return this.http.get(this.baseUrl + '/idUser/' + idUser);
    }

}
