import { Injectable } from "@angular/core";
import { FuseConfigService } from "@fuse/services/config.service";
import { takeUntil } from "rxjs/operators";
import { Observable, Subject } from "rxjs";
import { environment } from "environments/environment";
//import { OnlineStatusType } from 'ngx-online-status';
import { GlobalService } from "./services/global.service";
import { Router } from "@angular/router";
import { AppColors } from "./core/shared/state/models/appColors.model";
import { HttpClient } from "@angular/common/http";
declare var $;

@Injectable()
export class AppService {
    fuseConfig: any;
    private _unsubscribeAll: Subject<any>;
    private pagesResponsiveFixed = [
        "/free-trial",
        "/free-trial-educational",
        "/free-trial-particular",
        "/family-password",
        "/wellcome",
        "/login",
        "/admin-info",
        "/admin-guide",
        "/renew-licence",
        "/initial-registration",
        "/new-student",
        "/student-info",
        "/initial-registration",
        "/",
    ];

    reference1;

    constructor(
        private router: Router,
        private globalService: GlobalService,
        private _fuseConfigService: FuseConfigService,
        private _http: HttpClient
    ) {
        this._unsubscribeAll = new Subject();
        // this.setReference1();
    }

    getCurrentUrl(): string {
        return this.router.url;
    }

    changeFuseConfig(isHidden) {
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((config) => {
                config.layout.style = isHidden ? "" : environment.fuseConfig;
            });
    }

    mobileDetect() {
        let agent = window.navigator.userAgent;
        let d = document;
        let e = d.documentElement;
        let g = d.getElementsByTagName("body")[0];
        let deviceWidth = window.innerWidth || e.clientWidth || g.clientWidth;

        // Chrome
        // @ts-ignore: Unreachable code error
        let IsChromeApp = window.chrome && chrome.app && chrome.app.runtime;

        // iPhone
        let IsIPhone = agent.match(/iPhone/i) != null;

        // iPad up to IOS12
        let IsIPad =
            agent.match(/iPad/i) != null ||
            (agent.match(/iPhone/i) != null && deviceWidth > 750); // iPadPro when run with no launch screen can have error in userAgent reporting as an iPhone rather than an iPad. iPadPro width portrait 768, iPhone6 plus 414x736 but would probably always report 414 on app startup

        if (IsIPad) IsIPhone = false;

        // iPad from IOS13
        var macApp = agent.match(/Macintosh/i) != null;
        if (macApp) {
            // need to distinguish between Macbook and iPad
            var canvas = document.createElement("canvas");
            if (canvas != null) {
                var context =
                    canvas.getContext("webgl") ||
                    canvas.getContext("experimental-webgl");
                if (context) {
                    // @ts-ignore: Unreachable code error
                    var info = context.getExtension(
                        "WEBGL_debug_renderer_info"
                    );
                    if (info) {
                        // @ts-ignore: Unreachable code error
                        var renderer = context.getParameter(
                            info.UNMASKED_RENDERER_WEBGL
                        );
                        if (renderer.indexOf("Apple") != -1) IsIPad = true;
                    }
                }
            }
        }

        // IOS
        let IsIOSApp = IsIPad || IsIPhone;

        // Android
        let IsAndroid = agent.match(/Android/i) != null;
        let IsAndroidPhone = IsAndroid && deviceWidth <= 960;
        let IsAndroidTablet = IsAndroid && !IsAndroidPhone;

        let message = "";

        if (IsIPhone) {
            message = "Device is IsIPhone";
        } else if (IsIPad) {
            message = "Device is ipad";
        } else if (IsAndroidTablet || IsAndroidPhone || IsAndroid) {
            message = "Device is Android";
        } else {
            message = "Device is Mac ||  Windows Desktop";
        }

        // return {
        //     message: message,
        //     isTrue: IsIOSApp || IsAndroid || IsAndroidTablet || IsAndroidPhone
        // }

        // return IsIOSApp || IsAndroid || IsAndroidTablet || IsAndroidPhone;

        return {
            IsIPhone: IsIPhone,
            IsIPad: IsIPad,
            IsIOSApp: IsIOSApp,
            IsAndroid: IsAndroid,
            IsAndroidTablet: IsAndroidTablet,
            IsAndroidPhone: IsAndroidPhone,
            useKeyboard:
                IsAndroidPhone ||
                IsAndroidTablet ||
                IsAndroid ||
                IsIOSApp ||
                IsIPad,
            message: message,
        };
    }

    onResize(timeout = false) {
        let currentUrl = this.getCurrentUrl();

        // if (
        //     this.pagesResponsiveFixed.find(
        //         (p) => p == currentUrl.toLocaleLowerCase()
        //     ) !== undefined
        // ) {
        //     document.documentElement.style.setProperty(
        //         "--font-size", "10.7789px"
        //     );
        //     $(".walinwa-app").css("overflow", "hidden");
        //     $(".walinwa-app-contents").css("width", "");
        //     $(".walinwa-app-contents").css("height", "");
        //     document.querySelectorAll(".walinwa-app")[0].scrollTo(0, 0);
        //     $(".walinwa-app-contents").css("zoom", "");
        //     $(".walinwa-app").css("display", "");
        //     $(".walinwa-app").css("justify-content", "");
        //     $(".walinwa-app").css("align-items", "");
        //     return;
        // }

        // if (!timeout) {
        //     setTimeout(() => {
        //         this.onResize(true);
        //         setTimeout(() => {
        //             this.onResize(true);
        //         }, 2000);
        //     }, 1000);
        // }

        if (/android/i.test(navigator.userAgent)) {
            if (!$("input").is(":focus") && !$("textarea").is(":focus")) {
                // this.setReference1();
            }
        } else {
            //any device
            // this.setReference1();
        }
        // this.setFontSize();
        let isDesktop =
            !/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                navigator.userAgent
            );
        // let mobileDetect = this.mobileDetect();
        // if (!isDesktop) return;

        let screenCssPixelRatio = window.outerWidth / window.innerWidth;
        screenCssPixelRatio = Math.round(screenCssPixelRatio * 10) / 10;
        // document.getElementById("prueba").innerHTML = screenCssPixelRatio.toString();
        // if (screenCssPixelRatio == 0) return;

        // if (screenCssPixelRatio == 1) {
        //     $(".walinwa-app").css("overflow", "hidden");
        //     $(".walinwa-app-contents").css("width", "");
        //     $(".walinwa-app-contents").css("height", "");
        //     document.querySelectorAll(".walinwa-app")[0].scrollTo(0, 0);
        // } else {
        //     $(".walinwa-app").css("overflow", "auto");
        //     let width = 100 * screenCssPixelRatio + "vw";
        //     if (100 * screenCssPixelRatio < 90) width = "90vw";
        //     $(".walinwa-app-contents").css("width", width.toString());
        //     $(".walinwa-app-contents").css("height", "100vh");
        // }
        // if (screenCssPixelRatio < 1) {
        //     $(".walinwa-app").css("display", "flex");
        //     $(".walinwa-app").css("justify-content", "center");
        //     $(".walinwa-app").css("align-items", "center");
        // } else {
        //     $(".walinwa-app").css("display", "");
        //     $(".walinwa-app").css("justify-content", "");
        //     $(".walinwa-app").css("align-items", "");
        // }
        // $(".walinwa-app-contents").css("zoom", screenCssPixelRatio);
    }

    isMobile() {
        let isDesktop =
            !/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                navigator.userAgent
            );
        if (isDesktop) return false;
        let mobileMaxWidth = 620;
        let w = window.innerWidth;
        let h = window.innerHeight;
        let android = /Android/i.test(navigator.userAgent);
        let anyFocus = $("input").is(":focus") || $("select").is(":focus");
        let portrait = h > w;
        let landscape = !portrait;
        if (android && anyFocus && portrait) h *= 2;
        if (android && anyFocus && landscape) h *= 5;
        return (
            (w <= mobileMaxWidth && portrait) ||
            (h <= mobileMaxWidth && landscape)
        );
    }

    setReference1() {
        this.reference1 = $("body").height();
        if ($("body").height() > $("body").width()) {
            this.reference1 = $("body").width();
        }
    }

    setFontSize() {
        let sum = location.href.includes("student/scenary") ? 180 : 0;
        let reference2 = parseFloat("1024px");
        if ($("body").height() + sum > $("body").width()) {
            reference2 = parseFloat("1240px");
            if (this.isMobile()) {
                reference2 = parseFloat("640px");
            }
        }
        let pantalla_final = parseFloat(this.reference1) * 1.4;
        let d = (pantalla_final * 100) / reference2;
        d = 100 - d;
        d = d / 100;
        let t_inicial = 12;
        let t_final = t_inicial - 12 * d;
        let t_final_new = 16;
        $(":root").css("--font-size", t_final + "px");
        // $(":root").css("--font-size", t_final_new + "px");
    }

    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    public getAppColors(): Observable<AppColors[]> {
        return this._http.get<AppColors[]>(`${environment.baseApi}/api/App/GetAppColors`);
    }
}
