import { AppService } from 'app/app.service';
import { NavigationService } from './../../../../../services/navigation.service';
import { UiService } from './../../../../../../@fuse/components/spinner/ui.service';
import { SharedService } from './../../../../../core/shared/shared.service';
import { AuthService } from '../../../../../core/guards/auth.service';
import { AccountService } from './../../../account.service';
import { Component, HostListener, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators';
import { UserService } from 'app/services/user.service';
import { DataStore } from 'app/core/shared/state';

@Component({
    selector: 'app-admin-guide',
    templateUrl: './admin-guide.component.html',
    styleUrls: ['./admin-guide.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class AdminGuideComponent implements OnInit, OnDestroy {
    private _unsubscribeAll: Subject<any>;
    userinfo
     iPad = /iPad|Macintosh/i.test(navigator.userAgent); mobileDetect;
    mobile: boolean = false;
    constructor(private _accountService: AccountService,
        private _authService: AuthService,
        public _sharedService: SharedService,
        private userService: UserService,
        private _ui: UiService,
        private navigationService: NavigationService,
        private dataStore: DataStore,
        private appService: AppService) {
            this._unsubscribeAll = new Subject();
            this.mobileDetect = this.appService.mobileDetect();
    }

    ngOnDestroy() {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
    
    @HostListener('window:resize', ['$event'])
  onResize(event) {
    event.target.innerWidth;
    this.mobile = this._sharedService.isMobile();
  }
    ngOnInit(): void {
        this.mobile = this._sharedService.isMobile();
        this.userinfo = this.navigationService.params.userinfo
        this._sharedService.adminInfoForm = null;
        this.dataStore.resetData();
    }
    continue() {
        this._accountService.login(this.userinfo)
            
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(response => {
                this._authService.login(this._sharedService.json(response));

                this.userService
                .getUserByToken()
                .subscribe(
                    (result: any) => {
                        this._sharedService.redirectRol(result, null, 1);
                        this._ui.stopSpinner();
                    },
                    (error) => {
                    this._ui.stopSpinner();
                    }
                );
            });
    }
}
